
import React from "react";
import {
  FormWithRedirect,
  FormTab, SaveButton, DeleteButton
} from "react-admin";
import { generateInputField } from "../factory";
import CreateToolbar from './CreateToolbar'
import {
  useMediaQuery, makeStyles, Typography, Box, Grid, Toolbar

} from '@material-ui/core';

const useDatagridStyles = makeStyles({
  total: { fontWeight: 'bold' },
});


const UiCustomForm = (props) => (

  <FormWithRedirect
    {...props}
    render={({ resourcesFields, resourcesTabs, tabs, canDelete, formProps,id, ...props }) => (
      // here starts the custom form layout
      <form>
        <div style={{ padding: 10 }}>

          <Grid container spacing={3} borderRadius={16} borderColor="primary.main">
            {tabs.map((t, index) => {

              return (
                <>
                  <Grid borderRadius={16} borderColor="primary.main" item xs={resourcesTabs[index][0] == null ? 6 : resourcesTabs[index][0]['width_class']} >

                    <Typography variant="h6" gutterBottom>{t}</Typography>

                    {resourcesFields[index] && resourcesFields[index].map((f, index) =>
                      generateInputField(f, index,id)
                    )}
                  </Grid>


                </>

              );


            }

            )}

          </Grid>
        </div>
        <Toolbar>
          <Box display="flex" justifyContent="space-between" width="100%">
            <SaveButton {...props}
              //saving={formProps.saving}
              //handleSubmitSithRedirect={formProps.handleSubmitSithRedirect}
            />
                        {canDelete && <DeleteButton {...props} />}


           // record={formProps.record} 
            />
          </Box>
        </Toolbar>
      </form>
    )}
  />
);



export default UiCustomForm;