
import React from "react";
import { SaveButton, DeleteButton, Toolbar } from "react-admin";
import alasql from "alasql";
import WorkFlowButton from '../WorkFlowButton';


const CreateToolbar = props => {

    const { resource, record, basePath } = props;
    const role = localStorage.getItem("role");
    let resourcePermistions = alasql(`select * from resource_permistions  where resource_name == "${resource}" and ${role}_delete=true `);
    let canDelete = resourcePermistions.length > 0 ? true : false;

   
    //const { canDelete = false } = props;
    return (

        <Toolbar {...props}>

            <SaveButton
                label="ra.action.save"

            />





            {/*       <SaveButton
        label="ra.action.save_and_show"
        redirect="list"
        variant="text"
  
        submitOnEnter={false}
      /> 

                <SaveButton
                    label="ra.action.save_and_add"
                    redirect={false}
                    submitOnEnter={false}
                    variant="text"
                />
                */
            }

            {canDelete && <DeleteButton {...props} />}

        </Toolbar>
    );
};


export default CreateToolbar;