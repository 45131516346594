import React from 'react'
import PropTypes from 'prop-types'
import { default as ReactSelect } from 'react-select'
import { components } from 'react-select'
import makeAnimated from 'react-select/animated'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
const animatedComponents = makeAnimated()

const Option = props => {
    delete props.innerProps.onMouseMove;
    delete props.innerProps.onMouseOver;
  return (
    <div>
      <components.Option  width={300} {...props}>
      <FormControlLabel
        control={
          <Checkbox
            checked={props.isSelected} 
            onChange={() => null}
            color="primary"
          />
        }
        label={props.label}
      />
        
      </components.Option>
    </div>
  )
}

const MultiValue = props => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
)

const ExtendedMultiSelect = props => {
    
  if (props.allowSelectAll) {
    return (
      <ReactSelect  width={300}
        {...props}
        components={{ Option, MultiValue, animatedComponents, ...props.components }}
        options={[props.allOption, ...props.option]}
        onChange={(selected, event) => {
          if (selected !== null && selected.length > 0) {
            if (selected[selected.length - 1].value === props.allOption.value) {
              return props.onChange([props.allOption, ...props.option])
            }
            let result = []
            if (selected.length === props.option.length) {
              if (selected.includes(props.allOption)) {
                result = selected.filter(option => option.value !== props.allOption.value)
              } else if (event.action === 'select-option') {
                result = [props.allOption, ...props.option]
              }
              return props.onChange(result)
            }
          }

          return props.onChange(selected)
        }}
      />
    )
  }

  return <ReactSelect   width={300} {...props} />
}

ExtendedMultiSelect.propTypes = {
  option: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
}

ExtendedMultiSelect.defaultProps = {
  allOption: {
    label: 'Select all',
    value: '*',
  },
}

export default ExtendedMultiSelect