import React, { Component } from "react";
import { Create, SimpleForm, TabbedForm, FormTab, Toolbar, SaveButton } from "react-admin";
import alasql from "alasql";
import { generateInputField } from "./factory";
import { reduxForm } from 'redux-form';
import formMap from './ui/formMap'



class ResourceCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      resourcesFields: [],
      resourcesTabs: [],
      resourcePermistions: [],
      tabs: []
    };
  }

  async componentDidMount() {
    const { resource } = this.props;
    const role = localStorage.getItem('role');
    let resourcePermistions = await alasql.promise(
      `select form_mode,inline_create from resource_permistions  where resource_name ='${resource}' `
    );

    var inlineQuery='';
    if (resourcePermistions[0].inline_create)
      resourcePermistions[0].form_mode = 'UiInlineForm';

      if (resourcePermistions[0].inline_create){

        inlineQuery='and   is_inline=true';

      }

    // let tabsList = alasql('select distinct write_group as tab  from resources where write_group <> "" ').map(d=> d.tab );


    // let __resourcesFields = await alasql.promise(
    //   `select source_name, input_widget_type, field_meta from resources where resource_name ='${resource}'  and is_create_field =true
    //   AND ( role_${role}='write' )  
    //    `
    // );
    let tabs = alasql(`select distinct write_group as tab  from resources where is_create_field =true and resource_name ='${resource}' ${inlineQuery}  and  (role_${role}='read' OR role_${role}='write') `).map(({ tab }) => tab);
    let resourcesFields = alasql(`select distinct write_group as tab  from resources where  is_create_field =true and resource_name ='${resource}' ${inlineQuery} `).map(({ tab }) => alasql(`select  source_name, input_widget_type, field_meta, full_width,is_required, css_class   from resources where write_group ="${tab}" ${inlineQuery}  and resource_name ='${resource}'  and is_create_field =true
    AND ( role_${role}='write' ) `));
    let resourcesTabs = alasql(
      `select distinct write_group as tab  from resources where is_create_field =true and resource_name ='${resource}' ${inlineQuery} `
    ).map(({ tab }) =>
      alasql(`select  width_class,new_line_after  from custom_tabs where tab_id ="${tab}" ${inlineQuery}  and resource_name ='${resource}'    `)
    );
  
    this.setState({
      loading: false,
      resourcesFields,
      resourcesTabs,
      resourcePermistions,
      tabs
    });

  }

  renderField(f, index) {
    return generateInputField(f, index, this.props.id);
  }

  render() {
    const { loading, resourcesFields,resourcesTabs,resourcePermistions, tabs = [] } = this.state;
    if (loading) return "loading ...";


    //console.log('this.props', this.props)

    let redirect = "show"
    if (typeof this.props !== 'undefined' && typeof this.props.location !== 'undefined' && typeof this.props.location.state !== 'undefined' && typeof this.props.location.state.record !== 'undefined') {
      const { id, origin, tab } = this.props.location.state
      redirect = `/${origin}/${id}/show/${tab}`
    }
//redirect={redirect}
    return (
      <Create {...this.props} >
          {formMap(resourcePermistions[0].form_mode, resourcesFields, resourcesTabs, false, tabs, this.props.id)}

      </Create>
    );
  }
}



export default reduxForm({
  form: 'record-form',
  enableReinitialize: true,

})(ResourceCreate)