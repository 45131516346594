import Airtable from "airtable";

export const loadFromairtable = ({
  apiKey = "keySTexmSolnA1AYY",
  baseName = "appeLJskuVBijN4c6",
  tableName = "resource_permistions",
  viewName = "Grid view",
  fields=[]
}) =>
  new Promise(function(resolve, reject) {
    let results = [];
    var base = new Airtable({ apiKey: apiKey }).base(baseName);
    ////console.log(' base(tableName)', base(tableName))
    window.base = base(tableName)

    base(tableName)
      .select({
        maxRecords: 1000,
        view: viewName
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function(record) {
              let obj ={}
            fields.forEach(f=>{
                obj[f] = record.get(f)|| ""
            })
            results.push(obj);
          });

          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error(err);
            reject(err);
            return;
          }

          //console.log("done");

          resolve(results);
        }
      );
  });
