import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'

const TimeField = ({ source, record = {} }) => <span>{moment(record[source]).format('HH:mm')}</span>;

TimeField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default TimeField;