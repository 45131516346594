import hasuraDataProvider from "ra-data-hasura";



const headers = {
    "content-type": "application/json",
    "x-hasura-admin-secret": "N2PznzvRpeBu2DXkTahNbWyyJJTciNJ0PHx6K1U3tgrLq9kQBlflAzRdhcU9o8r2"
    //authorization: "bearer <token>"
};

const GRAPHQL_ENDPOINT = "https://matloob.hasura.app";




export const dataProvider = hasuraDataProvider(GRAPHQL_ENDPOINT, headers);
