
import React from "react";
import {
  TabbedForm,
  FormTab
} from "react-admin";
import { generateInputField } from "../factory";
import CreateToolbar from './CreateToolbar'
import {
  useMediaQuery, makeStyles

} from '@material-ui/core';
import { parse } from 'query-string';

const useDatagridStyles = makeStyles({
  total: { fontWeight: 'bold' },
});
class Form extends React.Component {


  is_defaultValue = false;



  render() {
    // to={`/${resource}/create?keyValue=${id}&keyName=${target}&backTo=${from}&addShortCut=true`}

    const {
      addShortCut: addShortCut_string,
      keyValue: keyValue_string,
      keyName: keyName_string,
      backTo: backTo_string,
      tab: tab_string,
    } = parse(window.location.hash);

    console.log(parse(window.location.hash));


    const { isXSmall, classes, filterValues, resourcesFields, tabs, canDelete, id, ...props } = this.props;
    var redirect = 'show';
    var keyValue = null;
    var keyName = null;
    var backTo = null;
    var defaultFilterValue = null;
    var tab = '';
    if (addShortCut_string == 'true') {

      this.is_defaultValue = true;
      keyValue = keyValue_string ? keyValue_string : '';
      keyName = keyName_string ? keyName_string : '';
      backTo = backTo_string ? backTo_string : '';
      tab = tab_string ? tab_string : '';

      redirect = keyValue && backTo ? `/${backTo}/${keyValue}/${tab}` : 'show';
      defaultFilterValue = keyValue && backTo ? { [keyName]: keyValue } : {};
      console.log(defaultFilterValue);

    }

    return (

      <TabbedForm {...props} defaultValue={this.is_defaultValue ? defaultFilterValue : {}}

        toolbar={<CreateToolbar canDelete={canDelete} />}
        redirect={redirect}
      >
        {tabs.map((t, index) => {
          //console.log('t, index', t, index, resourcesFields[index])

          return (
            <FormTab label={t} key={index}>


              {resourcesFields[index] && resourcesFields[index].map((f, index) =>
                generateInputField(f, index, id)
              )}

            </FormTab>
          )
        })}
      </TabbedForm>


    );
  }
}

const UiTabbedForm = props => {
  //console.log(props);
  const classes = useDatagridStyles();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  return <Form {...props} />;
};

export default UiTabbedForm;