
import React from "react";

import UiTabbedForm from './UiTabbedForm'
import UiCustomForm from './UiCustomForm'
import UiInlineForm from './UiInlineForm'
import UiExpandForm from './UiExpandForm'

export const formMap = (form_name, resourcesFields, resourcesTabs, canDelete, tabs,id) => {

    var component = '';
    //console.log(form_name);
  
  
    switch (form_name) {
      case "UiCustomForm":
        component = <UiCustomForm resourcesFields={resourcesFields} resourcesTabs={resourcesTabs} canDelete={canDelete} id={id} tabs={tabs} />
        break;
      case "UiTabbedForm":
        component = <UiTabbedForm resourcesFields={resourcesFields} resourcesTabs={resourcesTabs} canDelete={canDelete} id={id}  tabs={tabs} />
        break;
        case "UiInlineForm":
          component = <UiInlineForm resourcesFields={resourcesFields} resourcesTabs={resourcesTabs} canDelete={canDelete} id={id}  tabs={tabs} />
          break;
          case "UiExpandForm":
            component = <UiExpandForm resourcesFields={resourcesFields} resourcesTabs={resourcesTabs} canDelete={canDelete} id={id}  tabs={tabs} />
            break;
          

        
      default:
        component = <UiTabbedForm resourcesFields={resourcesFields} resourcesTabs={resourcesTabs} canDelete={canDelete}  id={id}  tabs={tabs} />
    }
    return component;
  };


  export default formMap;