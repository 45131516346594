import React, { Component } from "react";
import get from "lodash/get";
import MapGL, { Source, Layer, CustomLayer } from "@urbica/react-map-gl";
import { GeoJsonLayer } from '@deck.gl/layers';
import { MapboxLayer } from '@deck.gl/mapbox';

import { point, centroid, bbox } from "@turf/turf";
import "mapbox-gl/dist/mapbox-gl.css";

const MAPBOX_ACCESS_TOKEN = localStorage.getItem("MAPBOX_ACCESS_TOKEN");

export default class MapField extends Component {
  constructor(props) {
    super(props);
    this._map = React.createRef();

    const { source, record = {} } = props;
    const geojson = get(record, source);
    let latitude = 24.659112602053245;
    let longitude = 46.73292702525621;
    if (geojson) {
      let centroidPoint = centroid(geojson);
      longitude = centroidPoint.geometry.coordinates[0];
      latitude = centroidPoint.geometry.coordinates[1];
    }
    this.state = {
      myDeckLayer: null,
      viewport: {
        latitude,
        longitude,
        zoom: 7
      }
    };
  }

  componentDidMount() {
    const { source, record = {} } = this.props;
    const geojson = get(record, source);

    const map = this._map.current.getMap();
    map.once("load", () => {
      window.mapgl = map;
      if (geojson) {
        let _bbox = bbox(geojson);
        map.fitBounds(_bbox, { padding: 100 }, 0);
        this.setState({
          myDeckLayer: new MapboxLayer({
            id: 'geojson-layer',
            type: GeoJsonLayer,
            data:geojson,
            pickable: true,
            stroked: false,
            filled: true,
            extruded: true,
            lineWidthScale: 20,
            lineWidthMinPixels: 4,
            getFillColor: [160, 160, 180, 200],
            getLineColor: [30, 150, 255],
            getRadius: 100,
            getLineWidth: 1,
            getElevation: 30,
          })
        });

     

      }
    });
  }

  render() {
    const { className, source, name, record = {}, ...rest } = this.props;
    const { myDeckLayer } = this.state;

    //console.log('myDeckLayer',myDeckLayer)
    const geojson = get(record, source);
    const mapStyle = "mapbox://styles/mapbox/satellite-streets-v9"; //"mapbox://styles/mapbox/light-v9"
    return (
      <div>
        <MapGL
          ref={this._map}
          style={{ width: "100%", height: "600px", direction: "ltr" }}
          mapStyle={mapStyle}
          accessToken={MAPBOX_ACCESS_TOKEN}
          onViewportChange={viewport => this.setState({ viewport })}
          {...this.state.viewport}
        >
          {/* <Source id="maine" type="geojson" data={geojson} />
          <Layer
            id="maine"
            type="fill"
            source="maine"
            paint={{
              "fill-color": "#088",
              "fill-opacity": 0.8
            }}
          /> */}
  {myDeckLayer && <CustomLayer layer={myDeckLayer} /> }
        </MapGL>
        ;
      </div>
    );
  }
}
