import * as React from 'react';
import { Fragment, useState } from 'react';
import {
    Button,
    Confirm,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
} from 'react-admin';
import Icon from '@material-ui/core/Icon'

const ActionButton = ({ selectedIds,actData }) => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();

    const [updateMany, { loading }] = useUpdateMany(
        `${actData.resources}`,
        selectedIds,
        { [actData.StateColumn]: actData.StateValue },
        {
            onSuccess: () => {
                refresh();
                notify(`${actData.alert_accept}`);
                unselectAll(`${actData.Module}`);
            },
            onFailure: error => notify(`${actData.alert_error}`, 'warning'),
        }
    );
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        updateMany();
        setOpen(false);
    };

    return (
        <Fragment>
            <Button 
             variant="outlined"
             color="primary"
            label={actData.title} onClick={handleClick} />
          
            <Confirm
                isOpen={open}
                loading={loading}
                title={actData.title}
                content="سيتم الامر علي السجلات المختارة وفى حالة الفشل فى احداها سيكمل النظام الامر علي بقية السجلات مع اخطاركم بفشل البعض"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
}

export default ActionButton;