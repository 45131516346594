
import React, {Component, Fragment } from "react";
import { SaveButton, DeleteButton, EditButton, ShowButton, Datagrid, ReferenceManyField, List, TextInput } from "react-admin";
import alasql from "alasql";
import { asyncForEach } from '../../utils/utils'
import { generateInputField, generateField, generateFilterField } from "../factory";
import DefaultListGrid from './DefaultListGrid'
import TabedListGrid from './TabedListGrid'
import PostBulkActionButtons from './PostBulkActionButtons'
import ResourceEdit from "../EditExpand";


class ExpandTabsListGridWithActions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateColumn:null,
            stateValue:0
          };
        }
    onTabChange = (Column, Value) => {

        const { stateColumn, stateValue } = this.state;


        if (stateColumn != Column || Value != stateValue) {

            //  unselectAll();
            this.setState({
                stateColumn: Column,
                stateValue: Value
            })

        }

    }


  

   

    render() {
        const {linkedResourcePermistions,resource,resourcePermistions,resourcesFields,rowId } = this.props;

        const { stateColumn,stateValue } = this.state;
        return (
            <ReferenceManyField
            addLabel={false} 
            fullWidth
            reference={linkedResourcePermistions.expand_resource}
          //  filter={{ [metaData.target]: rowId?rowId:null}}
          filter={{ [linkedResourcePermistions.expand_destination]: linkedResourcePermistions.expand_source ? linkedResourcePermistions.expand_source : null }}
            target={linkedResourcePermistions.expand_destination}

            {...this.props}
        >
          
            <List   //{...fakeProps}  
            fullWidth
            bulkActionButtons={<PostBulkActionButtons  resource={resource}   Column={stateColumn} Value={stateValue}  />}
              exporter={false}
             filterDefaultValues={{ [resourcePermistions[0].list_tab_filter]: resourcePermistions[0].list_tab_filter_value }}>

                <TabedListGrid fullWidth
                    resourcePermistions={resourcePermistions[0]} onTabChange={this.onTabChange} resourcesFields={resourcesFields} />

            </List>
            </ReferenceManyField>


        );
    }
}

const ExpandGrid = props => {

    const { linkedResourcePermistions,record } = props;
    
    const role = localStorage.getItem('role');
    let resource = linkedResourcePermistions.expand_resource;
    let component='';





    let resourcesFields = alasql(
        `
     select source_name,field_widget_type, field_meta, css_class from resources 
     WHERE 
     resource_name ='${resource}' AND is_expand_field=true 
     AND ( role_${role}='read' OR role_${role}='write' ) 
     `
    );

    let resourcePermistions = alasql(
        `select extra,list_is_tab,list_tabs,list_tab_filter,list_tab_filter_value,resource_name,inline_update,inline_create, is_expand,expand_resource, expand_source, expand_destination,
     admin_show, admin_edit, admin_delete,supervisor_show, supervisor_edit, supervisor_delete from resource_permistions  where  resource_name ='${resource}' `
    );

    var sourceId="";
    var DestinationId="";
    var FsourceId=null;
    var FDestinationId=null;
    var setFilter=false;
    let Dresource = linkedResourcePermistions.resource_name;
    var expandEdit=false;
    var expandEditColumn="";
    var expandEditValue="";
    if (linkedResourcePermistions.extra && linkedResourcePermistions.extra!=''){
        let extra = JSON.parse(linkedResourcePermistions.extra);
        
        if(extra && extra.expandEdit=="true"){
            expandEdit=true;
             expandEditColumn=extra.expandEditColumn;
             expandEditValue=extra.expandEditValue;
        }

    }
    




    if (linkedResourcePermistions.expand_source.indexOf("|") >= 0){
        let res = linkedResourcePermistions.expand_source.split("|");
        sourceId=res[0];
        FsourceId=res[1];
    }else{
        sourceId=linkedResourcePermistions.expand_source;
        FsourceId=linkedResourcePermistions.expand_source;

    }
    if (linkedResourcePermistions.expand_destination.indexOf("|") >= 0){
        let res = linkedResourcePermistions.expand_destination.split("|");
        DestinationId=res[0];
        FDestinationId=res[1];
        }
        else{
            DestinationId=linkedResourcePermistions.expand_destination;
            FDestinationId=linkedResourcePermistions.expand_destination;

    }
  
    if(Dresource==resource){
        //alert('c');
    }

    if(Dresource!=resource){

  
   if(expandEdit){
    component = [<ReferenceManyField
        addLabel={false}
        reference={linkedResourcePermistions.expand_resource}
        source={sourceId}
        target={DestinationId}
        perPage={5000}
        {...props} 
       filter={FDestinationId!=null && FsourceId!=null && { [FDestinationId]: record[FsourceId] }}
        

         

    >
          <List   //{...fakeProps}  

        fullWidth
        bulkActionButtons={<PostBulkActionButtons  resource={linkedResourcePermistions.expand_resource}  Column={expandEditColumn} Value={expandEditValue}  />}
        //perPage={5000}
        pagination={false}
        //resource={linkedResourcePermistions.expand_resource}
        {...props} 
         exporter={false}
         filterDefaultValues={FDestinationId!=null && FsourceId!=null && { [FDestinationId]: record[FsourceId] }}>

        <DefaultListGrid  resourcePermistions={resourcePermistions[0]}   fullWidth   bulkActionButtons={false} exporter={false} resourcesFields={resourcesFields} />
</List>
    </ReferenceManyField>];

   }else{
    component = [<ReferenceManyField
        addLabel={false}
        reference={linkedResourcePermistions.expand_resource}
        source={sourceId}
        target={DestinationId}
        perPage={5000}
        {...props} 
       filter={FDestinationId!=null && FsourceId!=null && { [FDestinationId]: record[FsourceId] }}
        

         

    >
      


        <DefaultListGrid  resourcePermistions={resourcePermistions[0]}   fullWidth   bulkActionButtons={false} exporter={false} resourcesFields={resourcesFields} />
    </ReferenceManyField>];
   }


       

}else {
    component = [
        <ResourceEdit
                                       // className={classes.drawerContent}
                                       // id={isMatch ? match.params.id : null}
                                       // onCancel={this.handleClose}
                                        {...props}
                                    />

    ];

}

    if (resourcePermistions && resourcePermistions[0].list_is_tab) {
        resourcePermistions[0].list_tab_filter_value = parseInt(resourcePermistions[0].list_tab_filter_value);
      
 /*

        component = [

            <ExpandTabsListGridWithActions  {...props}  linkedResourcePermistions={linkedResourcePermistions} resource={linkedResourcePermistions.expand_destination} resourcesFields={resourcesFields}   resourcePermistions={resourcePermistions}  />

           


        ];
        */
        
    }




    return component;
}
    


export default ExpandGrid;