import React, { Component , Fragment,useCallback} from "react";
import {
    TextInput,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    CheckboxGroupInput,
    FileInput,
    ImageInput,
    NumberInput,
    RadioButtonGroupInput,
    TextField,
    ReferenceField,
    FileField,
    EmailField,
    ImageField,
    NumberField,
    SelectField,
    BooleanField,
    RichTextField,
    UrlField,
    ReferenceArrayInput,
    SelectArrayInput,
    AutocompleteInput,
    ReferenceManyField,
    Datagrid,
    EditButton,
    Button,
    SingleFieldList,
    ChipField,
    PasswordInput,
    ArrayInput,
    SimpleFormIterator,
    FormDataConsumer,
    FunctionField,
    required, ReferenceArrayField,
    ArrayField, List,DateField
} from "react-admin";
import { TimeInput, DateTimeInput, DateInput } from '../components/inputs/DateTimePicker'
import TimeField from './TimeField'

import { Field } from "redux-form";
import { Link } from "react-router-dom";
import RichTextInput from "ra-input-rich-text";
import IFrameField from "../components/IFrameField";
import MapInput from "../components/inputs/MapInput";
import MapField from "../components/fields/MapField";
import LiveLocationMapField from "../components/fields/LiveLocationMapField";
import LocationHistoryMapField from "../components/fields/LocationHistoryMapField";
import alasql from "alasql";
import DefaultListGrid from './ui/DefaultListGrid'
import TabedListGrid from './ui/TabedListGrid'
import AddMoreButton from "./ui/AddMoreButton";
import { ColorField, ColorInput } from 'react-admin-color-input';
import {useForm} from 'react-final-form';
import {factoryMapHook} from "../../factoryMapHook";
import PostBulkActionButtons from './ui/PostBulkActionButtons'



export const factoryMap = (f, index, input_widget, component, className, record,id) => {
    //console.log(record);
    const { field_meta, source_name, css_class, full_width, is_required } = f;

    let choices = {};
    let meta, reference, referancesRourceName, referancesSource;
    // var map = {
    //     password_input: passwordInput(component, source_name, index, css_class),
    //     autocompleate_input:autoCompleteInput(component,index,source_name,css_class),
    //     text_input: textInput(component, source_name, index, css_class),
    //     text_field: textField(component, source_name, index, css_class, className),
    //     long_text_input: longTextInput(component, source_name, index, css_class),
    //     map_input: mapInput(component, index, source_name, css_class),
    //     json_input: jsonInput(component, source_name, index, css_class),
    //     boolean_input: booleanInput(component, source_name, index, css_class),
    //     select_input: selectInput(choices, field_meta, component, source_name, index, css_class),
    //     date_time_input: dateTimeInput(component, source_name, index, css_class),
    //     pdf_input: pdfInput(component, source_name, index, css_class),
    //     image_input: imageInput(component, source_name, index, css_class),
    //     disabled_input: disabledInput(component, source_name, index, css_class),
    //     file_input: fileInput(component, source_name, index, css_class),
    //     number_input:numberInput(component, source_name, index, css_class),
    //     radio_button_group_input:radioButtonGroupInput(choices, field_meta, component, source_name, index, css_class),
    //     rich_text_input:richTextInput(component, source_name, index, css_class),
    //     referance_field_input:referenceFieldInput(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class),
    //     multi_array_input:multiArrayInput(meta, field_meta, reference, referancesRourceName, referancesSource, component, source_name, index, css_class),
    //     check_box_group_input:checkBoxGroupInput(choices, field_meta, component, source_name, index, css_class),
    //     referance_array_field_input:referenceArrayFieldInput(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, full_width, css_class),
    //     referance_array_field:referenceArrayField(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class),
    //     date_input:dateInput(component, source_name, index, css_class),
    //     reference_text:referenceText(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class, className),
    //     file_field:fileField(component, source_name, index),
    //     email_field:emailField(component, source_name, index, css_class, className),
    //     image_field_:imageField_(component, source_name, index, css_class, className),
    //     number_field:numberField(component, source_name, index, css_class, className),
    //     iframe_field:iframeField(component, source_name, index),
    //     select_field:selectField(choices, field_meta, component, source_name, index, css_class, className),
    //     boolean_field:booleanField(component, source_name, index, css_class, className),
    //     richtext_field:richTextField(component, source_name, index),
    //     json_field:richTextField(component, source_name, index),
    //     url_field:urlField(component, source_name, index, css_class, className),
    //     reference_many_field:referenceManyField(field_meta, component, index, record),
    //     pdf_field:pdfField(component, index, source_name),
    //     map_field:mapField(component, index, source_name),
    //     live_location_map_field:liveLocationMapField(component, index, source_name),
    //     location_history_map_field_:locationHistoryMapField(component, index, source_name),
    //     image_field:imageField(component, index, source_name, css_class, className)
    // };
    // debugger;
    // return  map[input_widget]();

    switch (input_widget) {
        case "password_input":
            component = passwordInput(component, source_name, index, css_class);
            break;
            case "password_input_change":
            component = passwordInputChange(component, source_name, index, css_class);
            break;
        case "autocompleate_input":
            component = autoCompleteInput(component, index, source_name, css_class);
            break;
        case "text_input":
            component = textInput(component, source_name, index, css_class, is_required);
            break;
            case "color_input":
                component = colorInput(component, source_name, index, css_class, is_required);
                break;
        case "text_field":
            component = textField(component, source_name, index, css_class, className);
            break;
        case "time_field":
            component = timeField(component, source_name, index, css_class, className);
            break;
            case "color_field":
                component = colorField(component, source_name, index, css_class, className);
                break;
        case "long_text_input":
            component = longTextInput(component, source_name, index, css_class,is_required,full_width);
            break;
        case "map_input":
            component = mapInput(component, index, source_name, css_class);
            break;

        case "json_input":
            component = jsonInput(component, source_name, index, css_class);
            break;
        case "boolean_input":
            component = booleanInput(component, source_name, index, css_class);
            break;

        case "select_input":
            ({ choices, component } = selectInput(choices, field_meta, component, source_name, index, css_class, is_required));
            break;

        case "date_time_input":
            component = dateTimeInput(component, source_name, index, css_class);
            break;
        case "time_input":
            component = timeInput(component, source_name, index, css_class);
            break;

        case "pdf_input":
            component = pdfInput(component, source_name, index, css_class);
            break;

        case "image_input":
            component = imageInput(component, source_name, index, css_class,is_required,full_width);
            break;
        case "disabled_input":
            component = disabledInput(component, source_name, index, css_class);
            break;
        case "file_input":
            component = fileInput(component, source_name, index, css_class);
            break;

        // case "image_input":
        //   component = <ImageInput source={source_name} key={index} />;
        //   break;

        case "number_input":
            component = numberInput(component, source_name, index, css_class, is_required);
            break;

        case "radio_button_group_input":
            ({ choices, component } = radioButtonGroupInput(choices, field_meta, component, source_name, index, css_class));
            break;
        case "rich_text_input":
            component = richTextInput(component, source_name, index, css_class);
            break;
            case"date_hijry":
            ({ meta, reference, referancesRourceName, referancesSource, component } = date_hijry(meta, field_meta, reference, referancesRourceName, referancesSource,component, index, css_class));
            break;

        case "referance_field_input":
            ({ meta, reference, referancesRourceName, referancesSource, component } = referenceFieldInput(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class, is_required));
            break;
        case "referance_field_filter_input":
            ({ meta, reference, referancesRourceName, referancesSource, component } = referenceFieldFilterInput(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class, full_width));
            break;
        case "referance_depenancy_input":
            ({ meta, reference, referancesRourceName, referancesSource, component } = referenceFieldDepenancyInput(source_name,meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class, full_width, is_required));
            break;
            case "referance_depenancy_input_search":
                ({ meta, reference, referancesRourceName, referancesSource, component } = referenceFieldDepenancyInputSearch(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class, full_width, is_required,source_name));
                break;
                case "referance_depenancy_input_search_multi":
                    ({ meta, reference, referancesRourceName, referancesSource, component } = referenceFieldDepenancyInputSearchMulti(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class, full_width, is_required,source_name));
                    break;

                
        case "multi_array_input":
            ({ meta, reference, referancesRourceName, referancesSource, component } = multiArrayInput(meta, field_meta, reference, referancesRourceName, referancesSource, component, source_name, index, css_class));
            break;
      
        case "check_box_group_input":
            ({ choices, component } = checkBoxGroupInput(choices, field_meta, component, source_name, index, css_class));
            break;
        case "referance_array_field_input":
            ({ meta, reference, referancesRourceName, referancesSource, component } = referenceArrayFieldInput(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, full_width, css_class));
            break;
            case "referance_array_field_input_dependancy":
                ({ meta, reference, referancesRourceName, referancesSource, component } = referenceArrayFieldInputDepandancy(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, full_width, css_class));
                break;
            
        case "referance_array_field":
            ({ meta, reference, referancesRourceName, referancesSource, component } = referenceArrayField(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class,source_name));
            break;
        case "multi_array_field":
            ({ meta, reference, referancesRourceName, referancesSource, component } = multiArrayField(meta, field_meta, source_name, reference, referancesRourceName, referancesSource, component, index, css_class));
            break;
     



        case "date_input":
            component = dateInput(component, source_name, index, css_class, full_width);
            break;

        case "reference_text":
            // ex: users.user_id.name
            ({ meta, reference, referancesRourceName, referancesSource, component } = referenceText(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class));
            break;

            case "reference_text_alt":
                // ex: users.user_id.name
                ({ meta, reference, referancesRourceName, referancesSource, component } = referenceTextDep(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class));
                break;

            

        case "file_field":
            component = fileField(component, source_name, index);
            break;

        case "email_field":
            component = emailField(component, source_name, index, css_class, className);
            break;
        case "image_field_":
            component = imageField_(component, source_name, index, css_class, className);
            break;
        case "number_field":
            component = numberField(component, source_name, index, css_class, className);
            break;
        case "iframe_field":
            component = iframeField(component, source_name, index);
            break;

        case "select_field":
            ({ choices, component } = selectField(choices, field_meta, component, source_name, index, css_class, className));
            break;

        case "boolean_field":
            component = booleanField(component, source_name, index, css_class, className);
            break;

        case "richtext_field":
        case "json_field":
            component = richTextField(component, source_name, index);
            break;

        case "url_field":
            component = urlField(component, source_name, index, css_class, className);
            break;

        case "reference_text":
            // ex: users.user_id.name
            ({ meta, reference, referancesRourceName, referancesSource, component } = referenceText(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class, className));
            break;

        case "reference_many_field":
            // ex: users.user_id.name
            component = referenceManyField(field_meta, component, index, record);
            break;

        case "reference_resource":
            // ex: users.user_id.name
            component = referenceResource(field_meta, component, index, record, f,id);
            break;
            case "reference_resource_show":
                // ex: users.user_id.name
                component = referenceResourceShow(field_meta, component, index, record, f,id);
                break;

        case "pdf_field":
            component = pdfField(component, index, source_name);
            break;

        case "map_field":
            component = mapField(component, index, source_name);
            break;

        case "live_location_map_field":
            component = liveLocationMapField(component, index, source_name);
            break;

        case "location_history_map_field_":
            component = locationHistoryMapField(component, index, source_name);
            break;

        case "image_field":
            component = imageField(component, index, source_name, css_class, className);
            break;
            case "date_field":
                component = dateField(component, source_name, index, css_class, className);
                break;

                case "long_text_field":
                    component = longTextField(component, source_name, index, css_class,full_width);
                    break;


        default:
            component=factoryMapHook(f, index, input_widget, component, className, record);

            break;
    }
    return component;
};

const TestA = (props) => {
    const form = useForm();

    //form.change('password', undefined);    

    const { resource, source, source_name, index, css_class } = props;
    form.change(source_name, '');    

    const disabled=true;
    return (
        <PasswordInput variant="outlined" label="كلمة المرور"  source={source_name} key={index} className={`input-field ${css_class}  `} /> 

    );
  };


class AutocompleteInputFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            choicesObject: []
        };
    }


    async componentDidMount() {
        const { resource, source } = this.props;
        //console.log(" this.props", this.props);

        let _choicesObject = [];
        let GRAPHQL_ENDPOINT = localStorage.getItem("GRAPHQL_ENDPOINT");
        let res = await fetch(`${GRAPHQL_ENDPOINT}/v1/graphql`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-hasura-admin-secret": "qiddiya"
            },
            body: JSON.stringify({
                query: `{ list:${resource}(distinct_on:${source}, where:{${source}:{_is_null: false}}){ value:${source} } }`
            })
        });
        let json = await res.json();
        _choicesObject = json.data.list.map(i => ({ id: i.value, name: i.value }));

        this.setState({
            loading: false,

            choicesObject: _choicesObject
        });
    }

    render() {
        const { resource, source } = this.props;

        const { choicesObject } = this.state;

        return (
            <AutocompleteInput
                shouldRenderSuggestions={val => {
                    return val.trim().length > 1;
                }}
                source={source}
                resource={resource}
                choices={choicesObject}
                {...this.props}
            />
        );
    }
}
const AddButton = ({ record, reference, source, target, index, origin, tab }) => {

    //console.log('origin, tab ', origin, tab)
    return (
        <Button
            variant="raised"
            component={Link}
            to={{
                pathname: `/${reference}/create`,
                //state: { [reference]: record.id },
                state: { record: { [target]: record.id }, id: record.id, reference, source, target, index, origin, tab },
            }}
            label={"إضافة"}
        ></Button>
    )
};



function autoCompleteInput(component, index, source_name, css_class) {
    component = (<AutocompleteInputFilter key={index} source={source_name} className={`input-field ${css_class}  `} />);
    return component;
}



function locationHistoryMapField(component, index, source_name) {
    component = (<Field key={index} name={source_name} source={source_name} component={LocationHistoryMapField} />);
    return component;
}

function liveLocationMapField(component, index, source_name) {
    component = (<Field key={index} name={source_name} source={source_name} component={LiveLocationMapField} />);
    return component;
}

function mapField(component, index, source_name) {
    component = (<Field key={index} name={source_name} source={source_name} component={MapField} />);
    return component;
}

function pdfField(component, index, source_name) {
    component = (<Field key={index} name={source_name} source={source_name} component={IFrameField} />);
    return component;
}

function referenceManyField(field_meta, component, index, record) {
    //console.log("field_meta", field_meta);
    const metaData = JSON.parse(field_meta);
    component = [
        <ReferenceManyField addLabel={false} reference={metaData.reference} key={index} target={metaData.target}>
            <Datagrid>
                {metaData.fields.map(field => (<TextField source={field} />))}

                <EditButton />
            </Datagrid>
        </ReferenceManyField>,
        <AddButton index={index} {...metaData} record={record} />
    ];
    return component;
}

function referenceTabsResource(field_meta, component, index, record) {
    //console.log("field_meta", field_meta);
    const metaData = JSON.parse(field_meta);

    const role = localStorage.getItem('role');
    let resource = metaData.reference;


    let resourcesFields = alasql(
        `
        select source_name,field_widget_type, field_meta, css_class from resources 
        WHERE 
        resource_name ='${resource}' AND is_list_field =true 
        AND ( role_${role}='read' OR role_${role}='write' ) 
        `
    );
    component = [
        <ReferenceManyField
            addLabel={false}
            reference={metaData.reference}
            target={metaData.target}
        >
            <TabedListGrid resourcePermistions={[]} resourcesFields={resourcesFields} />

        </ReferenceManyField>


        //<AddButton index={index} {...metaData} record={record} />
    ];
    return component;
}



function referenceResource(field_meta, component, index, record, f,id) {
    //console.log("field_meta", field_meta);
    const metaData = JSON.parse(field_meta);

    const role = localStorage.getItem('role');
    let resource = metaData.reference;
    //console.log(record);

  


    let resourcesFields = alasql(
        `
        select source_name,field_widget_type, field_meta, css_class from resources 
        WHERE 
        resource_name ='${resource}' AND is_list_field =true 
        AND ( role_${role}='read' OR role_${role}='write' ) 
        `
    );

    let resourcePermistions = alasql(
        `select is_expand,expand_resource, expand_source, expand_destination,list_is_tab,list_tabs,list_tab_filter,list_tab_filter_value,resource_name,inline_update,inline_create, 
        admin_show, admin_edit, admin_delete,supervisor_show, supervisor_edit, supervisor_delete from resource_permistions  where resource_name ='${resource}' `
    );

    if (resourcePermistions && resourcePermistions[0].list_is_tab) {
        resourcePermistions[0].list_tab_filter_value = parseInt(resourcePermistions[0].list_tab_filter_value);
     
     
        component = [
            <FormDataConsumer fullWidth className={`input-field   `}>
            {({ formData, ...rest }) => (
                <>
            <ReferenceManyField
            addLabel={false} 
            fullWidth
            reference={metaData.reference}
            target={metaData.target}
            perPage={5000}
            {...rest}
        >
          
            <List   //{...fakeProps}  
            fullWidth
             bulkActionButtons={false} exporter={false}
            filter={{ [metaData.target]: id?id:null}}
             filterDefaultValues={{ [resourcePermistions[0].list_tab_filter]: resourcePermistions[0].list_tab_filter_value }}>

                <TabedListGrid fullWidth
                    resourcePermistions={resourcePermistions[0]} resourcesFields={resourcesFields} />

            </List>
            </ReferenceManyField>
            <AddMoreButton  id={id} resource={metaData.reference} target={metaData.target} from={metaData.backTo} tab={metaData.tab} />
          
            </>
        )}
    </FormDataConsumer>
        ];
    }

    else {
        component = [
            <ReferenceManyField
                addLabel={false}
                reference={metaData.reference}
                target={metaData.target}
                perPage={5000}
            >
                <DefaultListGrid resourcePermistions={resourcePermistions[0]} resourcesFields={resourcesFields} />

            </ReferenceManyField>


            //<AddButton index={index} {...metaData} record={record} />
        ];
    }



    return component;
}


class TabsListGridWithActions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateColumn:null,
            stateValue:0
          };
        }
    onTabChange = (Column, Value) => {

        const { stateColumn, stateValue } = this.state;


        if (stateColumn != Column || Value != stateValue) {

            //  unselectAll();
            this.setState({
                stateColumn: Column,
                stateValue: Value
            })

        }

    }



    render() {
        const {metaData,resource,resourcePermistions,resourcesFields,rowId } = this.props;

        const { stateColumn,stateValue } = this.state;
        return (
            <ReferenceManyField
            addLabel={false} 
            fullWidth
            reference={metaData.reference}
            filter={{ [metaData.target]: rowId?rowId:null}}
            target={metaData.target}
            perPage={5000}

            {...this.props}
        >
          
            <List   //{...fakeProps}  
            fullWidth
            bulkActionButtons={<PostBulkActionButtons  resource={resource}   Column={stateColumn} Value={stateValue}  />}
              exporter={false}
             filterDefaultValues={{ [resourcePermistions[0].list_tab_filter]: resourcePermistions[0].list_tab_filter_value }}>

                <TabedListGrid fullWidth
                    resourcePermistions={resourcePermistions[0]} onTabChange={this.onTabChange} resourcesFields={resourcesFields} />

            </List>
            </ReferenceManyField>


        );
    }
}
function referenceResourceShow(field_meta, component, index, record, f,id) {
    //console.log("field_meta", field_meta);
    const metaData = JSON.parse(field_meta);

    const role = localStorage.getItem('role');
    let resource = metaData.reference;
    //console.log(record);

  


    let resourcesFields = alasql(
        `
        select source_name,field_widget_type, field_meta, css_class from resources 
        WHERE 
        resource_name ='${resource}' AND is_list_field =true 
        AND ( role_${role}='read' OR role_${role}='write' ) 
        `
    );

    let resourcePermistions = alasql(
        `select is_expand,expand_resource, expand_source, expand_destination,list_is_tab,list_tabs,list_tab_filter,list_tab_filter_value,resource_name,inline_update,inline_create, 
        admin_show, admin_edit, admin_delete,supervisor_show, supervisor_edit, supervisor_delete from resource_permistions  where resource_name ='${resource}' `
    );

    if (resourcePermistions && resourcePermistions[0].list_is_tab) {
        resourcePermistions[0].list_tab_filter_value = parseInt(resourcePermistions[0].list_tab_filter_value);
       
     
        component = [

            <TabsListGridWithActions metaData={metaData} resource={resource} resourcesFields={resourcesFields} rowId={id}  resourcePermistions={resourcePermistions}  />
          
           
           
  
        ];
    }

    else {
        component = [
            <ReferenceManyField
                addLabel={false}
                reference={metaData.reference}
                target={metaData.target}
                perPage={5000}
            >
                <DefaultListGrid resourcesFields={resourcesFields} />

            </ReferenceManyField>


            //<AddButton index={index} {...metaData} record={record} />
        ];
    }



    return component;
}
function referenceText(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class, className) {
    meta = field_meta.split(".");
    reference = meta[0];
    referancesRourceName = meta[1];
    referancesSource = meta[2];
    //console.log('>>>>', reference, referancesRourceName, referancesSource);
    component = (<ReferenceField link={false} source={referancesRourceName} reference={reference} key={index} className={`show-field ${css_class} ${className}`}>
        <TextField source={referancesSource} />
    </ReferenceField>);
    return { meta, reference, referancesRourceName, referancesSource, component };

}

function referenceTextDep(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class, className) {
    meta = field_meta.split(".");
    reference = meta[5];
    referancesRourceName = meta[6];
    referancesSource = meta[7];
    //console.log('>>>>', reference, referancesRourceName, referancesSource);
    component = (<ReferenceField link={false} source={referancesRourceName} reference={reference} key={index} className={`show-field ${css_class} ${className}`}>
        <TextField source={referancesSource} />
    </ReferenceField>);
    return { meta, reference, referancesRourceName, referancesSource, component };

}


function urlField(component, source_name, index, css_class, className) {
    component = (<UrlField source={source_name} key={index} className={`show-field ${css_class} ${className}`} />);
    return component;
}

function richTextField(component, source_name, index) {
    component = <RichTextField source={source_name} key={index} />;
    return component;
}

function booleanField(component, source_name, index, css_class, className) {
    component = (<BooleanField source={source_name} key={index} className={`show-field ${css_class} ${className}`} />);
    return component;
}
function dateField(component, source_name, index, css_class, className) {
    component = (<DateField source={source_name} key={index} className={`show-field ${css_class} ${className}`} />);
    return component;
}
function selectField(choices, field_meta, component, source_name, index, css_class, className) {
    choices = JSON.parse(field_meta);
    component = (<SelectField source={source_name} choices={choices} translateChoice={false} key={index} className={`show-field ${css_class} ${className}`} />);
    return { choices, component }; 

}

function iframeField(component, source_name, index) {
    component = <IFrameField source={source_name} key={index} />;
    return component;
}

function imageField_(component, source_name, index, css_class, className) {
    component = (<ImageField source={`${source_name}.src`} key={index} className={`image-field-container ${css_class} ${className}`} />);
    return component;
}
function imageField(component, index, source_name, css_class, className) {
    component = (<Field key={index} name={source_name} source={source_name} component={IFrameField} className={`show-field ${css_class} ${className}`} />);
    return component;
}

function numberField(component, source_name, index, css_class, className) {
    component = (<NumberField source={source_name} key={index} className={`show-field ${css_class} ${className}`} />);
    return component;
}

function fileField(component, source_name, index) {
    component = <FileField source={source_name} key={index} />;
    return component;
}

function emailField(component, source_name, index, css_class, className) {
    component = (<EmailField source={source_name} key={index} className={`show-field ${css_class} ${className}`} />);
    return component;
}



function dateInput(component, source_name, index, css_class, full_width) {
    component = (<DateInput source={source_name} key={index} fullWidth={full_width} className="input-field" className={`input-field ${css_class}  `} options={{ format: 'dd/MM/yyyy' }} />);
    return component;
}

function referenceArrayField(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class,source_name) {
    meta = field_meta.split(".");
    reference = meta[0];
    referancesRourceName = meta[1];
    referancesSource = meta[2];
    component = (<ReferenceArrayField label={' '+source_name} source={referancesRourceName} target='id' reference={reference} key={index} className={`input-field ${css_class}  `}>
        <SingleFieldList>
            <ChipField source={referancesSource} />
        </SingleFieldList>
    </ReferenceArrayField>);
    return { meta, reference, referancesRourceName, referancesSource, component };

}

function multiArrayField(meta, field_meta, source_name, reference, referancesRourceName, referancesSource, component, index, css_class) {
    meta = field_meta.split(".");
    reference = meta[0];
    referancesRourceName = meta[1];
    referancesSource = meta[2];
    component = (
        <ArrayField source={source_name}>
            <Datagrid>
                <ReferenceField link={false} source="contract_id" reference="domain_contracts" key={index}>
                    <TextField source="contract_number" />
                </ReferenceField>

                <ReferenceField link={false} source="contractor_id" reference="domain_contractors" key={index} >
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="allocated" />
                <TextField source="assigned" />

                <ReferenceField link={false} source="type_id" reference="domain_examination_types" key={index} >
                    <TextField source="type" />
                </ReferenceField>

                <ArrayField source="buses">
                    <Datagrid>
                        <ReferenceField link={false} source="bus_id" reference="bus" key={index} >
                            <TextField source="bus_number" />
                        </ReferenceField>
                        <TextField source="no_of_rounds" />
                        <TextField source="seat_number_first_round" />
                        <TextField source="seat_number_second_round" />
                    </Datagrid>
                </ArrayField>
            </Datagrid>

        </ArrayField>
    )
    return { meta, reference, referancesRourceName, referancesSource, component };
}



function referenceArrayFieldInput(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, full_width, css_class) {
    meta = field_meta.split(".");
    reference = meta[0];
    referancesRourceName = meta[1];
    referancesSource = meta[2];
    //console.log(meta);
    component = (<ReferenceArrayInput perPage={30}
        source={referancesRourceName}
        reference={reference} key={index} fullWidth={full_width} className={`input-field ${css_class}  `}>
        <SelectArrayInput optionText={referancesSource} />
    </ReferenceArrayInput>);
    return { meta, reference, referancesRourceName, referancesSource, component };

}

function referenceArrayFieldInputDepandancy(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class, full_width, is_required) {
    meta = field_meta.split(".");
    reference = meta[0];
    referancesRourceName = meta[1];
    referancesSource = meta[2];
    let filterForeignKey = meta[3];
    let filterSearchKeyWordValue = meta[4];
    //console.log(full_width);

    component =

        <FormDataConsumer fullWidth={full_width} className={`input-field ${css_class}  `}>
            {({ formData, ...rest }) => (
                <>
                    <ReferenceArrayInput
                        source={referancesRourceName}
                        reference={reference}
                        key={index}
                        perPage={30}


                        filter={{ [filterForeignKey]: formData[filterSearchKeyWordValue] }}
                        {...rest}
                    >
                        <SelectArrayInput optionText={referancesSource} validate={is_required ? [required()] : undefined} />


                    </ReferenceArrayInput>
                </>
            )}
        </FormDataConsumer>;

    return { meta, reference, referancesRourceName, referancesSource, component };
    //return component;
}

function checkBoxGroupInput(choices, field_meta, component, source_name, index, css_class) {
    choices = JSON.parse(field_meta);
    component = (<CheckboxGroupInput source={source_name} key={index} choices={choices} className={`input-field ${css_class}  `} />);
    // return { choices, component };
    return component;
}

function multiArrayInput(meta, field_meta, reference, referancesRourceName, referancesSource, component, source_name, index, css_class) {
    meta = field_meta.split(".");
    reference = meta[0];
    referancesRourceName = meta[1];
    referancesSource = meta[2];
    let deepmetaReference = meta[3];
    let deepReferancesRourceName = meta[4];
    let deepmetaReferancesSource = meta[5];
    component = (<ArrayInput source={source_name}>
        <SimpleFormIterator>
            <ReferenceInput source={referancesRourceName} reference={reference} key={index} className={`input-field ${css_class}  `}>
                <SelectInput optionText={referancesSource} />
            </ReferenceInput>

            <BooleanInput source='violation_form8' key={index} defaultValue={true} className={`input-field ${css_class}  `} />

            <BooleanInput source='need_processing' key={index} defaultValue={true} className={`input-field ${css_class}  `} />

            <BooleanInput source='need_pic_proof' key={index} defaultValue={true} className={`input-field ${css_class}  `} />
            <ArrayInput source="answers_settings">
                <SimpleFormIterator>
                    <ReferenceInput source={deepReferancesRourceName} reference={deepmetaReference} key={index} className={`input-field ${css_class}  `}>
                        <SelectInput optionText={deepmetaReferancesSource} />
                    </ReferenceInput>

                    <NumberInput source='timeout_in_days' key={index} className={`input-field ${css_class}  `} />

                    <BooleanInput source='timeout_enable' key={index} defaultValue={true} className={`input-field ${css_class}  `} />

                    <BooleanInput source='exclude_vehicle' key={index} defaultValue={true} className={`input-field ${css_class}  `} />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleFormIterator>

    </ArrayInput>);
    return { meta, reference, referancesRourceName, referancesSource, component };

}



function referenceFieldInput(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class, is_required) {
    meta = field_meta.split(".");
    reference = meta[0];
    referancesRourceName = meta[1];
    referancesSource = meta[2];
    //console.log(meta[3] ? { [meta[3]]: meta[4] } : undefined);
    component = (<ReferenceInput perPage={30} source={referancesRourceName} reference={reference} filter={meta[3] ? { [meta[3]]: meta[4] } : undefined} key={index} className={`input-field ${css_class}  `}>
        <SelectInput optionText={referancesSource} validate={is_required ? [required()] : undefined} />
    </ReferenceInput>);
    return { meta, reference, referancesRourceName, referancesSource, component };
    //return component;
}

function referenceFieldDepenancyInputSearch(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class, full_width, is_required,source_name) {
    meta = field_meta.split(".");
    reference = meta[0];
    referancesRourceName = meta[1];
    referancesSource = meta[2];
    let filterForeignKey = meta[3];
    let filterSearchKeyWordValue = meta[4];
 
    //console.log(full_width);

    component =

        <FormDataConsumer source={source_name} fullWidth={full_width} className={`input-field ${css_class}  `}>
            {({ formData, ...rest }) => (
                <>
                    <ReferenceInput
                        source={source_name}
                        reference={reference}
                        key={index}
                        perPage={50}


                        //filter={{ [filterForeignKey]: formData[filterSearchKeyWordValue] }}
                        filter={{ [filterForeignKey]: formData[filterSearchKeyWordValue] }}
                        filterToQuery={searchText => ({ [referancesSource]: searchText })} 

                        {...rest}
                    >
                        <AutocompleteInput optionText={referancesSource} validate={is_required ? [required()] : undefined} />


                    </ReferenceInput>
                </>
            )}
        </FormDataConsumer>;

    return { meta, reference, referancesRourceName, referancesSource, component };
    //return component;
}
function referenceFieldDepenancyInputSearchMulti(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class, full_width, is_required,source_name) {
    meta = field_meta.split(".");
    reference = meta[0];
    referancesRourceName = meta[1];
    referancesSource = meta[2];
    let filterForeignKey = meta[3];
    let filterSearchKeyWordValue = meta[4];

    let filterForeignKey2 = meta[5];
    let filterSearchKeyWordValue2 = meta[6];
    //console.log(full_width);

    component =

        <FormDataConsumer source={source_name} fullWidth={full_width} className={`input-field ${css_class}  `}>
            {({ formData, ...rest }) => (
                <>
                    <ReferenceInput
                        source={source_name}
                        reference={reference}
                        key={index}
                        perPage={50}


                        //filter={{ [filterForeignKey]: formData[filterSearchKeyWordValue] }}
                        filter={{[filterForeignKey2]: formData[filterSearchKeyWordValue2]  ,[filterForeignKey]: formData[filterSearchKeyWordValue]}}
                        filterToQuery={searchText => ({ [referancesSource]: searchText })} 

                        {...rest}
                    >
                        <AutocompleteInput optionText={referancesSource} validate={is_required ? [required()] : undefined} />


                    </ReferenceInput>
                </>
            )}
        </FormDataConsumer>;

    return { meta, reference, referancesRourceName, referancesSource, component };
    //return component;
}
function referenceFieldDepenancyInput(source_name,meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class, full_width, is_required) {
    meta = field_meta.split(".");
    reference = meta[0];
    referancesRourceName = meta[1];
    referancesSource = meta[2];
    let filterForeignKey = meta[3];
    let filterSearchKeyWordValue = meta[4];
    //console.log(full_width);

    component =

        <FormDataConsumer source={source_name} fullWidth={full_width} className={`input-field ${css_class}  `}>
            {({ formData, ...rest }) => (
                <>
                    <ReferenceInput
                        source={source_name}
                        reference={reference}
                        key={index}
                        perPage={30}


                        filter={{ [filterForeignKey]: formData[filterSearchKeyWordValue] }}
                        {...rest}
                    >
                        <SelectInput  optionText={referancesSource} validate={is_required ? [required()] : undefined} />


                    </ReferenceInput>
                </>
            )}
        </FormDataConsumer>;

    return { meta, reference, referancesRourceName, referancesSource, component };
    //return component;
}
function referenceFieldFilterInput(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class, full_width) {
    meta = field_meta.split(".");
    reference = meta[0];
    referancesRourceName = meta[1];
    referancesSource = meta[2];
    let filterForeignKey = meta[3];
    let filterSearchKeyWordValue = meta[4];
    //console.log(full_width);
    component =

        <FormDataConsumer fullWidth={full_width}>
            {({ formData, ...rest }) => (
                <>
                    <ReferenceArrayInput
                        source={referancesRourceName}
                        reference={reference}
                        key={index}
                        perPage={30}
                        className={`input-field ${css_class}  `}
                        filter={{ [filterForeignKey]: formData[filterSearchKeyWordValue] }}
                        {...rest}
                    >
                        <SelectArrayInput optionText={referancesSource} />


                    </ReferenceArrayInput>
                </>
            )}
        </FormDataConsumer>;

    return { meta, reference, referancesRourceName, referancesSource, component };
    //return component;
}

function richTextInput(component, source_name, index, css_class) {
    component = (<RichTextInput source={source_name} key={index} className={`input-field ${css_class}  `} />);
    return component;
}
function date_hijry(meta, field_meta, source_name, referancesRourceName, referancesSource, component, index, css_class) {
    meta = field_meta.split(".");
    source_name = meta[0];
    referancesRourceName = meta[1];
    referancesSource = meta[2];
    component = (
        <FunctionField source={source_name} render={record => `${record[source_name]}-${record[referancesRourceName]}-${record[referancesSource]}`} key={index} className={`input-field ${css_class}  `} />
    );
    return { meta, source_name, referancesRourceName, referancesSource, component };
}

function radioButtonGroupInput(choices, field_meta, component, source_name, index, css_class) {
    choices = JSON.parse(field_meta);
    component = (<RadioButtonGroupInput source={source_name} key={index} choices={choices} className={`input-field ${css_class}  `} />);
    return { choices, component };

}

function numberInput(component, source_name, index, css_class, is_required) {
    component = (<NumberInput source={source_name} key={index} validate={is_required ? [required()] : undefined} className={`input-field ${css_class}  `} />);
    return component;
}

function fileInput(component, source_name, index, css_class) {
    component = (<FileInput source={source_name} key={index} className={`input-field ${css_class}  `} />);
    return component;
}

function disabledInput(component, source_name, index, css_class) {
    component = (<TextInput source={source_name} key={index} disabled className={`input-field ${css_class}  `} />);
    return component;
}

function imageInput(component, source_name, index, css_class,is_required,full_width) {
    component = (<ImageInput source={source_name}  fullWidth={full_width} validate={is_required ? [required()] : undefined}  accept="image/*" key={index} className={`input-field ${css_class}  `}>
        <ImageField source="src" />
    </ImageInput>);
    return component;
}

function pdfInput(component, source_name, index, css_class) {
    component = (<FileInput source={source_name} accept="application/pdf" key={index} className={`input-field ${css_class}  `}>
        <UrlField source="src" />
    </FileInput>);
    return component;
}

function dateTimeInput(component, source_name, index, css_class) {
    component = (<DateTimeInput source={source_name} key={index} className={`input-field ${css_class}  `} options={{ format: 'dd/MM/yyyy, HH:mm:ss', ampm: false, clearable: true }} />);
    return component;
}

function timeInput(component, source_name, index, css_class) {
    component = (<TimeInput type='time' source={source_name} key={index} className={`input-field ${css_class}  `} options={{ format: 'HH:mm' }} />);
    return component;
}


function selectInput(choices, field_meta, component, source_name, index, css_class, is_required) {
    choices = JSON.parse(field_meta);
    component = (<SelectInput source={source_name} key={index} validate={is_required ? [required()] : undefined} choices={choices} className={`input-field ${css_class}  `} />);
    return { choices, component };

}

function booleanInput(component, source_name, index, css_class) {
    component = (<BooleanInput source={source_name} key={index} defaultValue={false} className={`input-field ${css_class}  `} />);
    return component;
}

function jsonInput(component, source_name, index, css_class) {
    component = (<TextInput multiline source={source_name} key={index} className={`input-field ${css_class}  `} />);
    return component;
}

function mapInput(component, index, source_name, css_class) {
    component = (<Field key={index} name={source_name} source={source_name} component={MapInput} className={`input-field ${css_class}  `} />);
    return component;
}

function passwordInput(component, source_name, index, css_class) {
    component = (<PasswordInput variant="outlined" source={source_name} key={index} className={`input-field ${css_class}  `} />);
    return component;
}
function passwordInputChange(component, source_name, index, css_class) {
    let disabled=true;

   
    component = [
        <TestA  source_name={source_name} index={index} css_class={css_class}  />    

    
    ];
    //component = (<PasswordInput variant="outlined" source={source_name} key={index} className={`input-field ${css_class}  `} />);
    return component;
}

function textInput(component, source_name, index, css_class, is_required) {
    component = (<TextInput variant="outlined" validate={is_required ? [required()] : undefined} source={source_name} key={index} className={`input-field ${css_class}  `} />);
    return component;
}
function colorInput(component, source_name, index, css_class, is_required) {
    component = (<ColorInput variant="outlined" validate={is_required ? [required()] : undefined} source={source_name} key={index} className={`input-field ${css_class}  `} />);
    return component;
}

function textField(component, source_name, index, css_class, className) {
    component = (<TextField source={source_name} key={index} className={`show-field ${css_class} ${className}`} />);
    return component;
}
function colorField(component, source_name, index, css_class, className) {
    component = (<ColorField source={source_name} key={index} className={`show-field ${css_class} ${className}`} />);
    return component;
}
function timeField(component, source_name, index, css_class, className) {
    component = (<TimeField source={source_name} key={index} className={`show-field ${css_class} ${className}`} />);
    return component;
}
function longTextInput(component, source_name, index, css_class,is_required,full_width) {
    component = (<TextInput multiline validate={is_required ? [required()] : undefined}  fullWidth={full_width}  source={source_name} key={index} className={`input-field ${css_class}  `} />);
    return component;
}
function longTextField(component, source_name, index, css_class,full_width) {
    component = (<TextField  fullWidth={full_width} source={source_name} key={index}  className={`input-field ${css_class}  `} />);
    return component;
}



