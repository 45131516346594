import * as React from 'react';
import ReactMapboxGl, {
  ScaleControl,
  ZoomControl,
  RotationControl,
  Layer,
  Feature
} from 'react-mapbox-gl';
import route from './route.json';

const MAPBOX_ACCESS_TOKEN = localStorage.getItem('MAPBOX_ACCESS_TOKEN');

const token =  MAPBOX_ACCESS_TOKEN

const mappedRoute = route.points.map(
  point => [point.lng, point.lat]
);

const Map = ReactMapboxGl({ accessToken: token });



const lineLayout = {
  'line-cap': 'round',
  'line-join': 'round'
};

const linePaint = {
  'line-color': '#4790E5',
  'line-width': 8,
  'line-opacity': 0.9
};

const polygonPaint = {
  'fill-color': '#6F788A',
  'fill-opacity': 0.7
};

const multiPolygonPaint = {
  'fill-color': '#3bb2d0',
  'fill-opacity': 0.5
};

const circlePaint = {
  'circle-radius': 10,
  'circle-color': '#E54E52',
  'circle-opacity': 1
};

class AllShapes extends React.Component {


  constructor(props) {
    super(props)
    this.intervalHandle = undefined;
    this.timeoutHandle = undefined;
   this.mounted = false;
    this.state = {
      center: [-0.120736, 51.5118219],
      zoom: [8],
      circleRadius: 30,
      routeIndex: 0
    }
  }
  


 

  componentWillMount() {
    this.mounted = true;
    this.timeoutHandle = setTimeout(() => {
      if (this.mounted) {
        this.setState({
          center: mappedRoute[0],
          zoom: [13],
          circleRadius: 10
        });
      }
    }, 3000);

    this.intervalHandle = setInterval(() => {
      if (this.mounted) {
        this.setState({
          routeIndex: this.state.routeIndex + 1
        });
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutHandle);
    clearInterval(this.intervalHandle);
  }

  getCirclePaint(){
    return {
    'circle-radius': this.state.circleRadius,
    'circle-color': '#E54E52',
    'circle-opacity': 0.8
  };
}

  onStyleLoad(map) {
    const { onStyleLoad } = this.props;
    return onStyleLoad && onStyleLoad(map);
  };

  render() {
    return (
      <Map
      style="mapbox://styles/mapbox/streets-v9" // eslint-disable-line
      containerStyle={{
        height: "600px",
        width: "100%",
        direction: "ltr"
      }}
        // tslint:disable-next-line:jsx-no-lambda
        onStyleLoad={(map)=>this.onStyleLoad(map)}
        center={this.state.center}
        zoom={this.state.zoom}
      >
        {/* Controls */}
        <ScaleControl />
        <ZoomControl />
        <RotationControl style={{ top: 80 }} />

        {/* Line example */}
        <Layer type="line" layout={lineLayout} paint={linePaint}>
          <Feature coordinates={mappedRoute} />
        </Layer>

        {/* Circle example */}
        <Layer type="circle" paint={circlePaint}>
          <Feature coordinates={mappedRoute[this.state.routeIndex]} />
        </Layer>

     
      </Map>
    );
  }
}

export default AllShapes;