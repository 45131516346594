import React, { Component, Fragment, useCallback } from "react";
import {
    TextInput,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    CheckboxGroupInput,
    FileInput,
    ImageInput,
    NumberInput,
    RadioButtonGroupInput,
    TextField,
    ReferenceField,
    FileField,
    EmailField,
    ImageField,
    NumberField,
    SelectField,
    BooleanField,
    RichTextField,
    UrlField,
    ReferenceArrayInput,
    SelectArrayInput,
    AutocompleteInput,
    ReferenceManyField,
    Datagrid,
    EditButton,
    Button,
    SingleFieldList,
    ChipField,
    PasswordInput,
    ArrayInput,
    SimpleFormIterator,
    FormDataConsumer,
    FunctionField,
    required, ReferenceArrayField,
    ArrayField, List
} from "react-admin";
import MarkdownInput from './components/inputs/RAExtendedMultiSelect'
import ListPhotoIcon from '@material-ui/core/ListItemIcon';
import {VisibilityOff,Description,PhotoCamera} from  '@material-ui/icons';

import { ColorField, ColorInput } from 'react-admin-color-input';


export const factoryMapHook = (f, index, input_widget, component, className, record, id) => {
    //console.log(record);
    const { field_meta, source_name, css_class, full_width, is_required } = f;

    let choices = {};
    let meta, reference, referancesRourceName, referancesSource;


    switch (input_widget) {

        case "matloob_form_input":
            ({
                meta,
                reference,
                referancesRourceName,
                referancesSource,
                component
            } = matloob_form_input(meta, field_meta, reference, referancesRourceName, referancesSource, component, source_name, index, css_class));
            break;





        case "referance_depenancy_input_search_multi_filter":
            ({ meta, reference, referancesRourceName, referancesSource, component } = referenceFieldDepenancyInputSearchMultiFilter(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class, full_width, is_required, source_name));
            break;


       
        case "answer_set_input":
            ({
                meta,
                reference,
                referancesRourceName,
                referancesSource,
                component
            } = answerSetInput(meta, field_meta, reference, referancesRourceName, referancesSource, component, source_name, index, css_class));
            break;
        case "school_plan_input":
            ({
                meta,
                reference,
                referancesRourceName,
                referancesSource,
                component
            } = schoolPlanInput(meta, field_meta, reference, referancesRourceName, referancesSource, component, source_name, index, css_class, is_required));
            break;
        case "bus_school_plan_input":
            ({
                meta,
                reference,
                referancesRourceName,
                referancesSource,
                component
            } = busSchoolPlanInput(meta, field_meta, reference, referancesRourceName, referancesSource, component, source_name, index, css_class, is_required));
            break;

        case "question_setting_field":
            component = questionSettingField(meta, field_meta, source_name, reference, referancesRourceName, referancesSource, component, index, css_class);
            break;
        case "answer_setting_field":
            component = answerSettingField(meta, field_meta, source_name, reference, referancesRourceName, referancesSource, component, index, css_class);
            break;
        case "school_plan_field":
            component = schoolPlanField(meta, field_meta, source_name, reference, referancesRourceName, referancesSource, component, index, css_class);
            break;
        case "bus_school_plan_field":
            component = busSchoolPlanField(meta, field_meta, source_name, reference, referancesRourceName, referancesSource, component, index, css_class);
            break;

        case "question_images":
            component = questionImage(meta, field_meta, source_name, reference, referancesRourceName, referancesSource, component, index, css_class);
            break;
        case "target_schools":
            component = target_schools(meta, field_meta, source_name, reference, referancesRourceName, referancesSource, component, index, css_class);
            break;

        case "target_inspectors":
            component = target_inspectors(meta, field_meta, source_name, reference, referancesRourceName, referancesSource, component, index, css_class);
            break;

        case "reference_plate":
            // ex: users.user_id.name
            ({
                meta,
                reference,
                referancesRourceName,
                referancesSource,
                component
            } = referencePlate(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class));
            break;

        case "icons_questions":
            // ex: users.user_id.name
             component = iconQuestions(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class);
            break;

            case "reference_photo_count":
                // ex: users.user_id.name
                ({ meta, reference, referancesRourceName, referancesSource, component } = referencePhotoCount(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class));
                break;
                case "reference_notes_count":
                    // ex: users.user_id.name
                    ({ meta, reference, referancesRourceName, referancesSource, component } = referenceNoteCount(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class));
                    break;


           

        default:

            break;
    }
    return component;
};


function questionArrayInput(meta, field_meta, reference, referancesRourceName, referancesSource, component, source_name, index, css_class) {
    meta = field_meta.split(".");
    reference = meta[0];
    referancesRourceName = meta[1];
    referancesSource = meta[2];
    let deepmetaReference = meta[3];
    let deepReferancesRourceName = meta[4];
    let deepmetaReferancesSource = meta[5];
    component = (<ArrayInput source={source_name}>
        <SimpleFormIterator>
            <ReferenceInput source={referancesRourceName} reference={reference} key={index} className={`input-field ${css_class}  `}>
                <SelectInput optionText={referancesSource} />
            </ReferenceInput>

            <ReferenceInput source={deepReferancesRourceName} reference={deepmetaReference} key={index} className={`input-field ${css_class}  `}>
                <SelectInput optionText={deepmetaReferancesSource} />
            </ReferenceInput>

            <BooleanInput source='is_mandatory' key={index} defaultValue={true} className={`input-field ${css_class}  `} />

            <BooleanInput source='violation_form8' key={index} defaultValue={true} className={`input-field ${css_class}  `} />

            <BooleanInput source='need_processing' key={index} defaultValue={true} className={`input-field ${css_class}  `} />

            <BooleanInput source='need_pic_proof' key={index} defaultValue={true} className={`input-field ${css_class}  `} />

            <NumberInput source="sort_score" defaultValue="1" />
            <NumberInput source="report8_code" defaultValue="0" />


        </SimpleFormIterator>

    </ArrayInput>);
    return { meta, reference, referancesRourceName, referancesSource, component };

}
function answerSetInput(meta, field_meta, reference, referancesRourceName, referancesSource, component, source_name, index, css_class) {
    meta = field_meta.split(".");
    let deepmetaReference = meta[0];
    let deepReferancesRourceName = meta[1];
    let deepmetaReferancesSource = meta[2];
    component = (
        <ArrayInput source="answers_settings">
            <SimpleFormIterator>
                <ReferenceInput source={deepReferancesRourceName} reference={deepmetaReference} key={index} className={`input-field ${css_class}  `}>
                    <SelectInput optionText={deepmetaReferancesSource} />
                </ReferenceInput>
                <ColorInput source='color' picker="Sketch" className={`input-field  ${css_class}  `} />

                <NumberInput source='timeout_in_days' key={index} className={`input-field ${css_class}  `} />


                <TextInput source='score' className={`input-field  ${css_class}  `} />
                <BooleanInput source='timeout_enable' key={index} defaultValue={true} className={`input-field ${css_class}  `} />

                <BooleanInput source='exclude_vehicle' key={index} defaultValue={true} className={`input-field ${css_class}  `} />
                <BooleanInput source='enable_score' key={index} defaultValue={true} className={`input-field  ${css_class}  `} />
                <BooleanInput source='is_answer_need_processing' key={index} defaultValue={true} className={`input-field ${css_class}  `} />


            </SimpleFormIterator>
        </ArrayInput>);
    return { meta, reference, referancesRourceName, referancesSource, component };

}
function schoolPlanInput(meta, field_meta, reference, referancesRourceName, referancesSource, component, source_name, index, css_class, is_required) {

    component = (<ArrayInput source={source_name} validate={is_required ? [required()] : undefined}>
        <SimpleFormIterator>
            <ReferenceInput source='type_id' reference='domain_examination_types' key={index} className={`input-field ${css_class}  `}>
                <SelectInput optionText='type' />
            </ReferenceInput>

            <ReferenceInput source='contractor_id' reference='domain_contractors' key={index} className={`input-field ${css_class}  `}>
                <SelectInput optionText='name' />
            </ReferenceInput>


            <FormDataConsumer>
                {({

                    formData, // The whole form data
                    scopedFormData, // The data for this item of the ArrayInput
                    getSource, // A function to get the valid source inside an ArrayInput
                    ...rest }) => (
                        <>
                            <ReferenceInput label="resources.schools.fields.contract_id" source={getSource('contract_id')} filter={{ operator_id: typeof scopedFormData !== 'undefined' ? scopedFormData.contractor_id : null }}
                                reference='domain_contracts' key={index} className={`input-field ${css_class}  `}>
                                <SelectInput optionText='contract_number' />
                            </ReferenceInput>

                        </>
                    )}
            </FormDataConsumer>
            <TextInput source='assigned' className={`input-field ${css_class}  `} />
            <TextInput source='allocated' className={`input-field ${css_class}  `} />

            <ArrayInput source="buses" >
                <SimpleFormIterator>
                    <ReferenceInput source='bus_id' reference='bus' key={index} className={`input-field ${css_class}  `}>
                        <SelectInput optionText='bus_number' />
                    </ReferenceInput>

                    <SelectInput source="no_of_rounds" choices={[
                        { id: 'first_round', name: 'رد' },
                        { id: 'second_round', name: 'ردين' },
                    ]} className={`input-field ${css_class}  `} />
                    <TextInput source='seat_number_first_round' className={`input-field ${css_class}  `} />
                    <TextInput source='seat_number_second_round' className={`input-field ${css_class}  `} />

                </SimpleFormIterator>
            </ArrayInput>

        </SimpleFormIterator>

    </ArrayInput>);
    return { meta, reference, referancesRourceName, referancesSource, component };

}
function busSchoolPlanInput(meta, field_meta, reference, referancesRourceName, referancesSource, component, source_name, index, css_class, is_required) {
    component = (<ArrayInput source={source_name} validate={is_required ? [required()] : undefined}>
        <SimpleFormIterator>
            <ReferenceInput source='type_id' reference='domain_examination_types' key={index} className={`input-field ${css_class}  `}>
                <SelectInput optionText='type' />
            </ReferenceInput>
            <ReferenceInput source='school_id' reference='schools' key={index} className={`input-field ${css_class}  `}>
                <SelectInput optionText='name' />
            </ReferenceInput>

            <ReferenceInput source='contractor_id' reference='domain_contractors' key={index} className={`input-field ${css_class}  `}>
                <SelectInput optionText='name' />
            </ReferenceInput>


            <FormDataConsumer>
                {({

                    formData, // The whole form data
                    scopedFormData, // The data for this item of the ArrayInput
                    getSource, // A function to get the valid source inside an ArrayInput
                    ...rest }) => (
                        <>
                            <ReferenceInput label="resources.schools.fields.contract_id" source={getSource('contract_id')} filter={{ operator_id: typeof scopedFormData !== 'undefined' ? scopedFormData.contractor_id : null }}
                                reference='domain_contracts' key={index} className={`input-field ${css_class}  `}>
                                <SelectInput optionText='contract_number' />
                            </ReferenceInput>

                        </>
                    )}
            </FormDataConsumer>
            <SelectInput source="no_of_rounds" choices={[
                { id: 'first_round', name: 'رد' },
                { id: 'second_round', name: 'ردين' },
            ]} className={`input-field ${css_class}  `} />
            <TextInput source='seat_number_first_round' className={`input-field ${css_class}  `} />
            <TextInput source='seat_number_second_round' className={`input-field ${css_class}  `} />

        </SimpleFormIterator>

    </ArrayInput>);
    return { meta, reference, referancesRourceName, referancesSource, component };

}


// TODO
function questionSettingField(meta, field_meta, source_name, reference, referancesRourceName, referancesSource, component, index, css_class) {
    // meta = field_meta.split(".");
    // reference = meta[0];
    // referancesRourceName = meta[1];
    // referancesSource = meta[2];
    component = (
        <ArrayField source={source_name}>
            <Datagrid>
                <ReferenceField link={false} source="question_id" reference="questions" key={index}>
                    <TextField source="question_text" />
                </ReferenceField>
                <BooleanField source="need_pic_proof" key={index} className={`show-field ${css_class}`} />
                <BooleanField source="need_processing" key={index} className={`show-field ${css_class}`} />
                <BooleanField source="violation_form8" key={index} className={`show-field ${css_class}`} />
                <BooleanField source="is_mandatory" key={index} className={`show-field ${css_class}`} />
                <ReferenceField link={false} source="answer_set_id" reference="answer_sets" key={index}>
                    <TextField source="description" />
                </ReferenceField>
                <ReferenceField link={false} source="answer_set_id" reference="answer_sets" key={index}>
                    <ArrayField source="answers_settings">
                        <Datagrid>
                            <ReferenceField link={false} source="answer_id" reference="answers" key={index} >
                                <TextField source="answer_text" />
                            </ReferenceField>
                            <BooleanField source="timeout_enable" key={index} className={`show-field ${css_class}`} />
                            <BooleanField source="exclude_vehicle" key={index} className={`show-field ${css_class}`} />
                            <TextField source="timeout_in_days" />
                        </Datagrid>
                    </ArrayField>
                </ReferenceField>
            </Datagrid>

        </ArrayField>
    )
    return component;
}

function answerSettingField(meta, field_meta, source_name, reference, referancesRourceName, referancesSource, component, index, css_class) {
    meta = field_meta.split(".");
    reference = meta[0];
    referancesRourceName = meta[1];
    referancesSource = meta[2];
    component = (
        <ArrayField source="answers_settings">
            <Datagrid>
                <ReferenceField link={false} source={referancesRourceName} reference={reference} key={index} >
                    <TextField source={referancesSource} />
                </ReferenceField>
                <BooleanField source="timeout_enable" key={index} className={`show-field ${css_class}`} />
                <BooleanField source="exclude_vehicle" key={index} className={`show-field ${css_class}`} />
                <TextField source="timeout_in_days" />
            </Datagrid>

        </ArrayField>
    )
    return component;
}

function schoolPlanField(meta, field_meta, source_name, reference, referancesRourceName, referancesSource, component, index, css_class) {
    // meta = field_meta.split(".");
    // reference = meta[0];
    // referancesRourceName = meta[1];
    // referancesSource = meta[2];
    component = (
        <ArrayField source={source_name}>
            <Datagrid>
                <ReferenceField link={false} source="contract_id" reference="domain_contracts" key={index}>
                    <TextField source="contract_number" />
                </ReferenceField>

                <ReferenceField link={false} source="contractor_id" reference="domain_contractors" key={index} >
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="allocated" />
                <TextField source="assigned" />

                <ReferenceField link={false} source="type_id" reference="domain_examination_types" key={index} >
                    <TextField source="type" />
                </ReferenceField>

                <ArrayField source="buses">
                    <Datagrid>
                        <ReferenceField link={false}  source="bus_id" reference="bus" key={index} >
                            <TextField source="bus_number" />
                        </ReferenceField>
                        <TextField source="no_of_rounds" />
                        <TextField source="seat_number_first_round" />
                        <TextField source="seat_number_second_round" />
                    </Datagrid>
                </ArrayField>
            </Datagrid>

        </ArrayField>
    )
    return component;
}

function busSchoolPlanField(meta, field_meta, source_name, reference, referancesRourceName, referancesSource, component, index, css_class) {
    // meta = field_meta.split(".");
    // reference = meta[0];
    // referancesRourceName = meta[1];
    // referancesSource = meta[2];
    component = (
        <ArrayField source={source_name}>
            <Datagrid>
                <ReferenceField link={false} source="type_id" reference="domain_examination_types" key={index} >
                    <TextField source="type" />
                </ReferenceField>

                <ReferenceField link={false} source="school_id" reference="schools" key={index}>
                    <TextField source="name" />
                </ReferenceField>

                <ReferenceField link={false} source="contract_id" reference="domain_contracts" key={index}>
                    <TextField source="contract_number" />
                </ReferenceField>

                <ReferenceField link={false} source="contractor_id" reference="domain_contractors" key={index} >
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="no_of_rounds" />
                <TextField source="seat_number_first_round" />
                <TextField source="seat_number_second_round" />
            </Datagrid>
        </ArrayField>
    )
    return component;
}


function questionImage(meta, field_meta, source_name, reference, referancesRourceName, referancesSource, component, index, css_class) {
    // meta = field_meta.split(".");
    // reference = meta[0];
    // referancesRourceName = meta[1];
    // referancesSource = meta[2];
    component = (
        <ArrayField source={source_name}>
            <Datagrid>

                <ImageField source="url" className={`image-field-container `} />

            </Datagrid>
        </ArrayField>
    )
    return component;
}

function referencePlate(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class, className) {
    meta = field_meta.split(".");
    reference = meta[0];
    referancesRourceName = meta[1];
    referancesSource = meta[2];
    //console.log('>>>>', reference, referancesRourceName, referancesSource);
    component = (<ReferenceField link={false} source={referancesRourceName} reference={reference} key={index} className={`show-field ${css_class} ${className}`}>
        <FunctionField label={referancesSource} render={record => `${record.plate_number} ${record.plate_symbol3} ${record.plate_symbol2} ${record.plate_symbol1}   `} />

    </ReferenceField>);
    return { meta, reference, referancesRourceName, referancesSource, component };

}

function referenceFieldDepenancyInputSearchMultiFilter(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class, full_width, is_required, source_name) {
    meta = field_meta.split(".");
    reference = meta[8];
    referancesRourceName = meta[1];
    referancesSource = meta[2];
    let filterForeignKey = meta[3];
    let filterSearchKeyWordValue = meta[4];

    let filterForeignKey2 = meta[5];
    let filterSearchKeyWordValue2 = meta[6];
    //console.log(full_width);



    component = (<ReferenceInput perPage={5000} source={source_name} reference={reference} key={index} className={`input-field ${css_class}  `}>
        <SelectInput optionText={referancesSource} validate={is_required ? [required()] : undefined} />
    </ReferenceInput>);



    return { meta, reference, referancesRourceName, referancesSource, component };
    //return component;
}


function target_schools(meta, field_meta, source_name, reference, referancesRourceName, referancesSource, component, index, css_class) {

    component = (
        <ReferenceInput
            source='target_schools'
            reference="schools"
            key={index}
            perPage={5000}
            className={`input-field ${css_class}  `}


        // filter={{ [filterForeignKey]: formData[filterSearchKeyWordValue] }}

        >
            <MarkdownInput />



        </ReferenceInput>



    )
    return component;

}
function target_inspectors(meta, field_meta, source_name, reference, referancesRourceName, referancesSource, component, index, css_class) {

    component = (
        <FormDataConsumer fullWidth={true}>
            {({ formData, ...rest }) => (
                <>
                    <ReferenceInput
                        source='target_inspectors'
                        reference="employees_as_rows"
                        key={index}
                        perPage={5000}
                        className={`input-field ${css_class}  `}
                        fullWidth={true}

                        filter={{ sub_zone_id: formData['administration_id'] }}

                    >
                        <MarkdownInput fullWidth={true} />



                    </ReferenceInput>
                </>
            )}
        </FormDataConsumer>


    )
    return component;
}




const FullNameField = ({ record = {} }) => <span> {record.note != '' && record.note != null && <Description />}  {record.images && record.images.length > 0 && <PhotoCamera />}</span>;
FullNameField.defaultProps = { label: 'المرفقات' };


function iconQuestions(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class, className) {


    component = (
       // <FullNameField source='id' />
       <FullNameField/>
    );
    return component;

}




function referenceNoteCount(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class, className) {
    meta = field_meta.split(".");
    reference = meta[0];
    referancesRourceName = meta[1];
    referancesSource = meta[2];
    //console.log('>>>>', reference, referancesRourceName, referancesSource);
    component = (<ReferenceField sortable={false} link={false} source='id' label="الملاحظات"  reference="inspection_statistics" key={index} className={`show-field ${css_class} ${className}`}>
        <TextField source="total_notes" /> 
    </ReferenceField>);
    return { meta, reference, referancesRourceName, referancesSource, component };
}

function referencePhotoCount(meta, field_meta, reference, referancesRourceName, referancesSource, component, index, css_class, className) {
    meta = field_meta.split(".");
    reference = meta[0];
    referancesRourceName = meta[1];
    referancesSource = meta[2];
    //console.log('>>>>', reference, referancesRourceName, referancesSource);
    component = (<ReferenceField link={false} sortable={false} link={false} source='id' label="الصور" reference="inspection_statistics" key={index} className={`show-field ${css_class} ${className}`}>
        <TextField source="total_image" /> 
    </ReferenceField>);
    return { meta, reference, referancesRourceName, referancesSource, component };
}




function matloob_form_input(meta, field_meta, reference, referancesRourceName, referancesSource, component, source_name, index, css_class) {
    meta = field_meta.split(".");
    reference = meta[0];
    referancesRourceName = meta[1];
    referancesSource = meta[2];
    let deepmetaReference = meta[3];
    let deepReferancesRourceName = meta[4];
    let deepmetaReferancesSource = meta[5];
    component = (<ArrayInput source={source_name}>
        <SimpleFormIterator >
        <TextInput source="props.labelText"  label="عنوان الحقل"  className={`input-field ${css_class}  `}/>


        <SelectInput className={`input-field ${css_class}  `} source="name"  label="الحقل بقواعد البيانات" choices={[
   {
    "id": "title",
    "name": "title"
  },
  {
    "id": "description",
    "name": "description"
  },
  {
    "id": "body",
    "name": "body"
  },
  {
    "id": "pictures",
    "name": "pictures"
  },
  {
    "id": "request_type",
    "name": "request_type"
  },
  {
    "id": "cat_id",
    "name": "cat_id"
  },
  {
    "id": "city_id",
    "name": "city_id"
  },
  {
    "id": "sub_cat_id",
    "name": "sub_cat_id"
  },
  {
    "id": "gender_id",
    "name": "gender_id"
  },
  {
    "id": "manufacturer_id",
    "name": "manufacturer_id"
  },
  {
    "id": "work_type_id",
    "name": "work_type_id"
  },
  {
    "id": "work_classification_id",
    "name": "work_classification_id"
  },
  {
    "id": "condition_id",
    "name": "condition_id"
  },
  {
    "id": "car_odometer",
    "name": "car_odometer"
  },
  {
    "id": "car_engine_type_id",
    "name": "car_engine_type_id"
  },
  {
    "id": "delivery_included",
    "name": "delivery_included"
  },
  {
    "id": "target_price",
    "name": "target_price"
  },
  {
    "id": "payment_type_id",
    "name": "payment_type_id"
  },
  {
    "id": "deadline_date",
    "name": "deadline_date"
  },
  {
    "id": "offer_receive_type_id",
    "name": "offer_receive_type_id"
  },
  {
    "id": "offers_type_id",
    "name": "offers_type_id"
  },
  {
    "id": "offers_show_action",
    "name": "offers_show_action"
  },
  {
    "id": "installation_included",
    "name": "installation_included"
  }
]} />


<SelectInput className={`input-field ${css_class}  `} source="widget"  label="توع الحقل" choices={[
   {
    "id": "session",
    "name": "session"
  },
  {
    "id": "hidden",
    "name": "hidden"
  },
  {
    "id": "location",
    "name": "location"
  },
  {
    "id": "switch",
    "name": "switch"
  },
  {
    "id": "text",
    "name": "text"
  },
  {
    "id": "price",
    "name": "price"
  },
  {
    "id": "text_english",
    "name": "text_english"
  },
  {
    "id": "number",
    "name": "number"
  },
  {
    "id": "checkbox",
    "name": "checkbox"
  },
  {
    "id": "attachment",
    "name": "attachment"
  },
  {
    "id": "__select",
    "name": "__select"
  },
  {
    "id": "date_picker",
    "name": "date_picker"
  },
  {
    "id": "select",
    "name": "select"
  }
]} />
      <SelectInput className={`input-field ${css_class}  `} source="props.lookupName"  label="مصدر البيانات" choices={[
           {
            "id": "",
            "name": "بدون مصدر بيانات"
          },
   {
    "id": "cat_id",
    "name": "cat_id"
  },
  {
    "id": "categories",
    "name": "categories"
  },
  {
    "id": "lookup_cities",
    "name": "lookup_cities"
  },
  {
    "id": "lookup_condtions",
    "name": "lookup_condtions"
  },
  {
    "id": "lookup_engine_types",
    "name": "lookup_engine_types"
  },
  {
    "id": "lookup_genders",
    "name": "lookup_genders"
  },
  {
    "id": "lookup_payment_types",
    "name": "lookup_payment_types"
  },
  {
    "id": "lookup_request_types",
    "name": "lookup_request_types"
  },
  {
    "id": "lookup_work_classifications",
    "name": "lookup_work_classifications"
  },
  {
    "id": "offer_receive_type_id",
    "name": "offer_receive_type_id"
  },
  {
    "id": "offers_type_id",
    "name": "offers_type_id"
  },
  {
    "id": "offers_show_action",
    "name": "offers_show_action"
  },

  {
    "id": "lookup_pin_plans",
    "name": "lookup_pin_plans"
  },

  {
    "id": "lookup_manufacturers",
    "name": "lookup_manufacturers"
  },

  {
    "id": "lookup_sub_cats",
    "name": "lookup_sub_cats"
  }
]} />

        <TextInput source="props.hintText"  label="التلميح" className={`input-field ${css_class}  `} />
        <TextInput source="props.errorText"  label="رسالة الخطأ"  className={`input-field ${css_class}  `}/>
   
        <BooleanInput source='enabled'  label="مفعل"  defaultValue={true} className={`input-field ${css_class}  `} />
<BooleanInput source='required'   label="اجباري" defaultValue={false} className={`input-field ${css_class}  `} />

<BooleanInput source='props.required'  label="التحقق اجباري" defaultValue={false} className={`input-field ${css_class}  `} />


        </SimpleFormIterator>

    </ArrayInput>);
    return { meta, reference, referancesRourceName, referancesSource, component };

}


