
import React from "react";
import { SaveButton, DeleteButton, Toolbar } from "react-admin";
import alasql from "alasql";
import WorkFlowButton from '../WorkFlowButton';
const styles = {
    drawerContent: {
        padding: 15
    }
  };
  


const ECreateToolbar = props => {

    const { record, basePath , data, resource, columns } = props;
    console.log(props);
    const role = localStorage.getItem("role");
    let resourcePermistions = alasql(`select * from resource_permistions  where resource_name == "${resource}" and ${role}_delete=true `);
    let canDelete = resourcePermistions.length > 0 ? true : false;

      //if (loading) return "loading ...";
      var Hooks = [];
      var workflow = 'none';
    
      let actions = [];
    
      if (typeof record !== 'undefined') {
          
          const role = localStorage.getItem('role');
    
          //get workflow key
          let stc = alasql(`select distinct StateColumn as col  from workflows where Module ='${resource}' and   role_${role}='control' `).map(({ col }) => col);
          if (typeof stc !== 'undefined' && typeof stc[0] !== 'undefined') {
              //if we have key check type and get list of actions json for current step
              console.log(`${record[stc[0]]}`);
              console.log(isNaN(`${record[stc[0]]}`));
    
              if (isNaN(`${record[stc[0]]}`)) {
    
                  actions = alasql(`select * from workflows where Module ='${resource}' and   role_${role}='control' and  StateColumn='${record[0]}' and StateValue='${record[stc[0]]}'`).map(col => col);
              } else {
                  //actions = alasql(`select * from workflows where Module ='${resource}' and   role_${role}='control' and  StateColumn='${stc[0]}' and StateValue=${record[stc[0]]}`).map(col => col);
                  actions = alasql(`select * from workflows where Module ='${resource}' and   role_${role}='control' and  StateColumn='${stc[0]}' and StateValue='${record[stc[0]]}'`).map(col => col);
    
              }

              console.log(`select * from workflows where Module ='${resource}' and   role_${role}='control' and  StateColumn='${record[0]}' and StateValue='${record[stc[0]]}'`);
              //get actions objects
    
              if (typeof actions[0] !== 'undefined' && typeof actions[0].Actions !== 'undefined') {
                  let actData = actions[0].Actions;
                  if (actData != '[]') {
    
                      Hooks = alasql(`select * from workflows where Module ='${resource}' and   role_${role}='control' and  WorkFlowName= ANY (ARRAY${actData})`).map(col => col);
                      workflow = actions[0].WorkFlowName;
                  }
              }
          }
      }
console.log(Hooks);
   
    //const { canDelete = false } = props;
    return (

        <Toolbar {...props}>

           

{Hooks.map((f, index) =>  <SaveButton className={styles.drawerContent} redirect={false}   label={f.title}    transform={data => ({ ...data, [f.StateColumn]: f.StateValue })}     submitOnEnter={false}    />)}




            {/*       <SaveButton
        label="ra.action.save_and_show"
        redirect="list"
        variant="text"
  
        submitOnEnter={false}
      /> 

                <SaveButton
                    label="ra.action.save_and_add"
                    redirect={false}
                    submitOnEnter={false}
                    variant="text"
                />
                */
            }

            {canDelete && <DeleteButton {...props} />}

        </Toolbar>
    );
};


export default ECreateToolbar;