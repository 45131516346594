import React, { Component , Fragment, cloneElement, useMemo } from "react";
import {
  List,CardActions,CreateButton,TopToolbar, ExportButton, Button, sanitizeListRestProps,useUnselectAll
} from "react-admin";
import { reduxForm } from 'redux-form'
// import jsonExport from 'jsonexport/dist';
import alasql from "alasql";


import ActionButton from "../ActionButton";


const PostBulkActionButtons = ({ props,resource,Column,Value,selectedIds }) => {


    //if (loading) return "loading ...";
    var Hooks = [];
    var workflow = 'none';
  
    let actions = [];
  
    if (Column != null) {
      const role = localStorage.getItem('role');
  
      actions = alasql(`select * from workflows where Module ='${resource}' and   role_${role}='control' and  StateColumn='${Column}' and StateValue='${Value}'`).map(col => col);
  
      //get actions objects
  
      if (typeof actions[0] !== 'undefined' && typeof actions[0].Actions !== 'undefined') {
        let actData = actions[0].Actions;
        if (actData != '[]') {
  
          Hooks = alasql(`select * from workflows where Module ='${resource}' and   role_${role}='control' and  WorkFlowName= ANY (ARRAY${actData})`).map(col => col);
          workflow = actions[0].WorkFlowName;
        }
  
      }
  
    }
  
   
  
  
    
    return (
      <Fragment>
  
        {Hooks.map((f, index) => <ActionButton selectedIds={selectedIds} actData={f} label="Reset Views" {...props}  />)}
  
      </Fragment>
    );
    }


    export default PostBulkActionButtons;