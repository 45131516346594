import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showNotification as showNotificationAction  ,UPDATE} from 'react-admin';
import { push as pushAction } from 'react-router-redux';
import Button from '@material-ui/core/Button';
import { dataProvider } from '../../dataProvider';


class  WorkFlowButton extends Component {
    handleClick = () => {
        const { push, record, showNotification,actData } = this.props;
        const updatedRecord = { ...record, [actData.StateColumn]: actData.StateValue };
        dataProvider(UPDATE, `${actData.Module}`, { id: record.id, data: updatedRecord })
            .then(() => {
                showNotification(`${actData.alert_accept}`);
                push(`/${actData.Module}`);
            })
            .catch((e) => {
                console.error(e);
                showNotification(`${actData.alert_error}`, 'warning')
            });
    }

    render() {
        const { actData } = this.props;
 
        return <Button onClick={this.handleClick}>{actData.title}</Button>;

    }
}

WorkFlowButton.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
    actData: PropTypes.object,

};

export default connect(null, {
    showNotification: showNotificationAction,
    push: pushAction,
})(WorkFlowButton);