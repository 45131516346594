import React, { Component } from "react";
import {
  Edit,

  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  ShowButton, useEditController,DeleteButton
} from "react-admin";
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import alasql from "alasql";
import { generateInputField } from "./factory";

import formMap from './ui/formMap';
import WorkFlowButton from './WorkFlowButton';


const EditActions = ({ basePath, data, resource, columns }) => {

  //if (loading) return "loading ...";
  var Hooks = [];
  var workflow = 'none';

  let actions = [];

  if (typeof data !== 'undefined') {
      const role = localStorage.getItem('role');

      //get workflow key
      let stc = alasql(`select distinct StateColumn as col  from workflows where Module ='${resource}' and   role_${role}='control' `).map(({ col }) => col);

      if (typeof stc !== 'undefined' && typeof stc[0] !== 'undefined') {
          //if we have key check type and get list of actions json for current step
          console.log(isNaN(`${data[stc[0]]}`));

          if (isNaN(`${data[stc[0]]}`)) {

              actions = alasql(`select * from workflows where Module ='${resource}' and   role_${role}='control' and  StateColumn='${stc[0]}' and StateValue='${data[stc[0]]}'`).map(col => col);
          } else {
              //actions = alasql(`select * from workflows where Module ='${resource}' and   role_${role}='control' and  StateColumn='${stc[0]}' and StateValue=${record[stc[0]]}`).map(col => col);
              actions = alasql(`select * from workflows where Module ='${resource}' and   role_${role}='control' and  StateColumn='${stc[0]}' and StateValue='${data[stc[0]]}'`).map(col => col);

          }
          //get actions objects

          if (typeof actions[0] !== 'undefined' && typeof actions[0].Actions !== 'undefined') {
              let actData = actions[0].Actions;
              if (actData != '[]') {

                  Hooks = alasql(`select * from workflows where Module ='${resource}' and   role_${role}='control' and  WorkFlowName= ANY (ARRAY${actData})`).map(col => col);
                  workflow = actions[0].WorkFlowName;
              }
          }
      }
  }
  return (
    <TopToolbar>
      <ShowButton basePath={basePath} record={data} />


    </TopToolbar>
  )
};


const mapStateToProps = state => ({
  // recordLiveValues: getFormValues('record-form')(state)
  recordLiveValues: state
});

const PostEdit = props => {

  let state = {
    loading: true,
    resourcesFields: [],
    resourcesTabs: [],
    resourcePermistions: [],
    tabs: [],
    canDelete: false,
 
  };

  const {
    loading,

    canDelete
  } = state;
  //console.log('resourcePermistions', resourcePermistions)
  const { recordLiveValues, resource, id } = props;



  const role = localStorage.getItem("role");


  let resourcePermistions = alasql.promise(
    `select form_mode,inline_update from resource_permistions  where resource_name ='${resource}' `
  );

  var inlineQuery = '';
  var form_mode = 'UiTabbedForm';

  if (resourcePermistions && resourcePermistions[0]) {
    if (resourcePermistions[0].inline_update) {
      resourcePermistions[0].form_mode = 'UiInlineForm';

    }
    
    form_mode = resourcePermistions[0].form_mode;

    if (resourcePermistions[0].inline_update) {

      inlineQuery = 'and   is_inline=true';

    }

  }


  let tabs = alasql(
    `select distinct write_group as tab  from resources where resource_name ='${resource}'  ${inlineQuery}  and   is_edit_field =true and  role_${role}='write' `
  ).map(({ tab }) => tab);


  let resourcesFields = alasql(
    `select distinct write_group as tab  from resources where resource_name ='${resource}'  ${inlineQuery} `
  ).map(({ tab }) =>
    alasql(`select  source_name, edit_input_widget_type as input_widget_type, field_meta , annotation, css_class, edit_css_class, full_width,is_required  from resources where write_group ="${tab}" and resource_name ='${resource}'  and is_edit_field =true
  AND ( role_${role}='write' ) ${inlineQuery} `)
  );

  let resourcesTabs = alasql(
    `select distinct write_group as tab  from resources where resource_name ='${resource}' ${inlineQuery} `
  ).map(({ tab }) =>
    alasql(`select  width_class,new_line_after  from custom_tabs where tab_id ="${tab}" and resource_name ='${resource}'  ${inlineQuery}   `)
  );


  // filter resourcesFields
  resourcesFields = resourcesFields.filter(d => d.length > 0);

  let headerFields = alasql(`select  source_name,edit_input_widget_type as input_widget_type, field_meta , annotation , css_class, edit_css_class, full_width, is_required from resources where  resource_name ='${resource}'  and is_edit_field =true
  AND ( role_${role}='read' ) and annotation ="header"`);



 



  return (
    <>
      {resourcePermistions && resourcePermistions[0] && !resourcePermistions[0].inline_update && headerFields.length > 0 && (
        <Show {...props} actions={<></>}>
          <SimpleShowLayout>
            {headerFields.map((f, index) => (
              <TextField
                source={f.source_name}
                className="show-field-compressed"
                key={index}
              />
            ))}
          </SimpleShowLayout>
          <hr />

        </Show>

      )}
      <Edit 
      actions={form_mode!='UiInlineForm' && <EditActions  />} 
       {...props}>

        {formMap('UiExpandForm', resourcesFields, resourcesTabs, canDelete, tabs, id)}






      </Edit>
    </>
  );
}




export default PostEdit