import React, { useState, useEffect, useCallback } from 'react'
import { useVersion, useDataProvider } from 'react-admin'
import { useMediaQuery } from '@material-ui/core'

import Visits from './Visits'
//import PendingReviews from './PendingReviews';
//import NewCustomers from './NewCustomers';

import { usePermissions } from 'react-admin'

import ScheduleIcon from '@material-ui/icons/Schedule'
import RateReviewIcon from '@material-ui/icons/RateReview'
import ReplayIcon from '@material-ui/icons/Replay'
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation'
import * as icons from '@material-ui/icons'

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  rightCol: { flex: 1, marginLeft: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
}

const Dashboard = () => {
  const [state, setState] = useState({
    statistics: {},
  })
  const version = useVersion()
  const dataProvider = useDataProvider()
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const dashbordCards = useCallback(async () => {
    //const aMonthAgo = new Date();
    //aMonthAgo.setDate(aMonthAgo.getDate() - 30);
    const { data: recentTransactions } = await dataProvider.getList(
      'dashboard',
      {
        filter: {},
        sort: { field: 'section', order: 'asc' },
        pagination: { page: 1, perPage: 500 },
      },
    )

    const aggregations = recentTransactions.reduce(
      (stats, transaction) => {
        stats.statistics[transaction.section] =
          typeof stats.statistics[transaction.section] === 'object' &&
          stats.statistics[transaction.section] !== null
            ? stats.statistics[transaction.section]
            : {
                key: transaction.section,
                data: [],
              }
        stats.statistics[transaction.section]['data'].push({
          title: transaction.title,
          icon: transaction.icon,
          value: transaction.value,
          color: transaction.color,
        })

        return stats
      },
      {
        statistics: {},
      },
    )

    setState((state) => ({
      ...state,

      statistics: aggregations.statistics,

      // recentVisits: visitData,
    }))
  }, [dataProvider])

  useEffect(() => {
    dashbordCards()
  }, [version]) // eslint-disable-line react-hooks/exhaustive-deps

  const {

    statistics,
  } = state

  const { loaded, permissions } = usePermissions()
  console.log(statistics)

  return loaded ? (
    isXSmall ? (
        <div>
        <div style={styles.flexColumn}>
        {Object.keys(statistics).map(function (section) {
          return (
            <>
              <div style={styles.singleCol}>{statistics[section]['key']}</div>
              <div style={styles.flex}>
                {statistics[section]['data'].map(function (item) {
                  return (
                    <Visits
                      value={item.value}
                      icon={icons[item.icon]}
                      color={item.color}
                      title={item.title}
                    />
                  )
                })}
              </div>
            </>
          )
        })}
      </div>
      </div>

     
    ) : isSmall ? (
      <div style={styles.flexColumn}>
        {Object.keys(statistics).map(function (section) {
          return (
            <>
              <div style={styles.singleCol}>{statistics[section]['key']}</div>
              <div style={styles.flex}>
                {statistics[section]['data'].map(function (item) {
                  return (
                    <Visits
                      value={item.value}
                      icon={icons[item.icon]}
                      color={item.color}
                      title={item.title}
                    />
                  )
                })}
              </div>
            </>
          )
        })}
      </div>
    ) : (
      <div style={styles.flex}>
        <div style={styles.rightCol}>
          {Object.keys(statistics).map(function (section) {
            return (
              <>
                <div style={styles.singleCol}>{statistics[section]['key']}</div>
                <div style={styles.flex}>
                  {statistics[section]['data'].map(function (item) {
                    return (
                      <Visits
                        value={item.value}
                        icon={icons[item.icon]}
                        color={item.color}
                        title={item.title}
                      />
                    )
                  })}
                </div>
              </>
            )
          })}
        </div>
      </div>
    )
  ) : null
}

export default Dashboard
