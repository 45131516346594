import React, { Component } from 'react'
import ExtendedMultiSelect from './ExtendedMultiSelect'
import {optimizeSelect} from './optimizeSelect'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AutoComplete, { createFilter } from "react-select";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { addField, FieldTitle } from 'ra-core';
import TextField from '@material-ui/core/TextField';
const styles = {};

class MarkdownInput extends Component {
  static propTypes = {
    input: PropTypes.object,
    source: PropTypes.string,
  };

  constructor(props) {

    super(props);
    this.state = {
      value: []
    };
  }

  componentDidMount() {
    const {
      input: {value}
    } = this.props;
    console.log( this.props);

    this.setState({value});

    
  }

  handleValueChange(value) {
    this.setState({value});
    this.props.input.onChange(value);
    //this.forceUpdate();
    console.log('New value : ', value)

  };



  render() {
    //Check https://react-select.com/home on how to configure options
  

    const { choices = [],optionText = 'name',optionValue = 'id'} = this.props;

    

    var option = choices.map(function(x) { 
      return { 
        value: x[optionValue], 
        label: x[optionText] 
      }; 
    });
    const {
      label,
      source,
      meta,
      className,
  
      resource,
      helperText,
      isRequired,
    } = this.props;

    const {
      touched,
      error,
    } = meta;

  
    return (
      // Control Group is optional
        <ExtendedMultiSelect
        {...this.props}
          key={'key'}
          option={option} //Check https://react-select.com/home
          isMulti={true}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          onChange={value=>this.handleValueChange(value)}
          value={this.state.value}
          allowSelectAll={true}
          isRtl={true}
          rightAligned={true}
          label={
            <FieldTitle
                label={label}
                source={source}
                resource={resource}
                isRequired={isRequired}
            />
          }
          error={!!(touched && error)}
          helperText={touched && error || helperText}
          className={className}
         // filterOption={createFilter({ ignoreAccents: false })}
          components={optimizeSelect.components}
        ></ExtendedMultiSelect>
    )
  }
}

const MarkDownInputWithField = addField(withStyles(styles)(MarkdownInput));

MarkDownInputWithField.defaultProps = {
  addLabel: true,
  fullWidth: true,
  optionText: 'name',
  optionValue: 'id',
};
export default MarkDownInputWithField;


