import React, { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import styled from "styled-components";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const IframeContainer = styled.div`
  width: 100%;
  min-width: 900px;
  clear: both;
`;

export default class IFrameField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  handleChange(e, o) {
    //console.log(e, o);
    this.setState({
      open: o
    });
  }

  render() {
    const { open } = this.state;
    const { className, source, name, record = {} } = this.props;
    let src = null;
    const value = get(record, source);
    if (value) src = value.src;

    if (src)
      return (
        <div>
          <IframeContainer>
            <FormControlLabel
              control={<Switch onChange={(e, o) => this.handleChange(e, o)} />}
              label="عرض المرفق"
            />
            {open && (
              <iframe
                width="100%"
                height="700px"
                frameBorder="0"
                src={src}
                title="iframe"
              />
            )}
          </IframeContainer>
        </div>
      );
    return <div />;
  }
}

IFrameField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired
};
