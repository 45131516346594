
import React from "react";
import { SaveButton, DeleteButton, EditButton, ShowButton, Datagrid } from "react-admin";
import ExpandGrid from "./ExpandGrid";

import { generateField } from "../factory";
import {
  useMediaQuery,makeStyles

} from '@material-ui/core';

const useDatagridStyles = makeStyles({
  total: { fontWeight: 'bold' },
});
class DefaultGrid extends React.Component {


  render() {
    const { isXSmall, classes, filterValues,resourcesFields, resourcePermistions, ...props } = this.props;
    const role = localStorage.getItem('role');

    return (


      <Datagrid {...props}

      expand={resourcePermistions && resourcePermistions.is_expand ? <ExpandGrid linkedResourcePermistions={resourcePermistions}/> : null}

      >
        {resourcesFields.map((f, index) => generateField(f, index, props.record, 'list-view',props.id))}
        
        {resourcePermistions && resourcePermistions[`${role}_edit`]== true && <EditButton />}
        {resourcePermistions && resourcePermistions[`${role}_delete`]== true && <DeleteButton />}

        <ShowButton {...props} />
      </Datagrid>
    );
  }
}

const DefaultListGrid = props => {
  //console.log(props);
  const classes = useDatagridStyles();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  return <DefaultGrid {...props} />;
};

export default DefaultListGrid;