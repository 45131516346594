
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import LabelIcon from '@material-ui/icons/Label';
import { useMediaQuery } from '@material-ui/core';
import { useTranslate, DashboardMenuItem, MenuItemLink } from 'react-admin';

import alasql from "alasql";

import SubMenu from './SubMenu';

import DirectionsBus from "@material-ui/icons/DirectionsBus";
import VerticalSplit from "@material-ui/icons/VerticalSplit";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";

const icons = {
    "directions_bus": <DirectionsBus />,
    "vertical_split": <VerticalSplit />,
    "settings": <SettingsIcon />,
    "recent_actors": <RecentActorsIcon />,
    "settings_applications": <SettingsApplicationsIcon />,
    "keyboard_arrow_left": <KeyboardArrowLeftIcon />,
    "airport_shuttle": <AirportShuttleIcon />,
}




const MenuData = () => {

    const role = localStorage.getItem('role');


    let tabs = alasql(
        `select distinct menu_section as tab  from resource_permistions where ${role}_list=true  `
    ).map(({ tab }) => tab);

    let resourcesMenus = alasql(
        `select distinct menu_section as tab  from resource_permistions where ${role}_list=true `
    ).map(({ tab }) =>
        alasql(`select  '/'+resource_name as url,resource_name as title ,'keyboard_arrow_left' as icon from resource_permistions where menu_section="${tab}" and ${role}_list=true
   `)
    );

    return tabs.map((t, index) => {
        ////console.log('t, index', t, index, resourcesMenus[index]);
        return { "name": "section" + index, "title": t, "icon": "vertical_split", "links": resourcesMenus[index] };

    });



}

const Menu = ({ onMenuClick, dense, logout }) => {
    const [state, setState] = useState({});
    const translate = useTranslate();
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    useSelector(state => state.theme); // force rerender on theme change

    const handleToggle = menu => {
        let reverse_state = typeof state[menu] !== 'undefined' ? !state[menu] : false;

        setState(state => ({ ...state, [menu]: reverse_state }));
    };


    return (
        <div>
            {" "}
            <DashboardMenuItem onClick={onMenuClick} />
            {MenuData().map(({ name, title, icon: iconName, links }) => (
                <SubMenu
                    handleToggle={() => handleToggle(name)}
                    isOpen={typeof state[name] !== 'undefined' ? state[name] : true}
                    sidebarIsOpen={open}
                    name={translate(`sections.${title}`, {
                        smart_count: 2,
                    })}
                    icon={icons[iconName]}
                >
                    {links.map(({ title, url, icon: subIconNmme }) => (
                        <MenuItemLink
                            to={url}
                            primaryText={translate(`resources.${title}.name`, {
                                smart_count: 2,
                            })}
                            leftIcon={icons[subIconNmme]}
                            onClick={onMenuClick}
                        />
                    ))}
                </SubMenu>
            ))}
        </div>
    );




};

Menu.propTypes = {
    onMenuClick: PropTypes.func,
    logout: PropTypes.object,
};

export default Menu;