import React from 'react';
import { Link } from 'react-router-dom';
import AddBubbleIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
  button: {
    marginTop: '1em'
  }
};

const AddMoreButton = ({ classes, id,resource,target,from,tab }) => (
  <Button
    className={classes.button}
    variant="raised"
    component={Link}
    to={`/${resource}/create/?fastAdd=true&keyValue=${id}&keyName=${target}&tab=${tab}&backTo=${from}&addShortCut=true`}
    label="اضافة سجل جديد"
    title="اضافة سجل جديد"
  >
    <AddBubbleIcon />
  </Button>
);

export default withStyles(styles)(AddMoreButton);
