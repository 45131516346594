import React, { Component } from "react";
import { reduxForm } from 'redux-form'

import { Show, SimpleShowLayout,  TabbedShowLayout, Tab ,
  TextField, EditButton, TopToolbar } from "react-admin";
import alasql from "alasql";
import { generateField } from "./factory";
import WorkFlowButton from './WorkFlowButton';

const ShowActions = ({ basePath, data, resource, columns }) => {

  //if (loading) return "loading ...";
  var Hooks = [];
  var workflow = 'none';

  let actions = [];

  if (typeof data !== 'undefined') {
      const role = localStorage.getItem('role');

      //get workflow key
      let stc = alasql(`select distinct StateColumn as col  from workflows where Module ='${resource}' and   role_${role}='control' `).map(({ col }) => col);

      if (typeof stc !== 'undefined' && typeof stc[0] !== 'undefined') {
          //if we have key check type and get list of actions json for current step
          console.log(isNaN(`${data[stc[0]]}`));

          if (isNaN(`${data[stc[0]]}`)) {

              actions = alasql(`select * from workflows where Module ='${resource}' and   role_${role}='control' and  StateColumn='${stc[0]}' and StateValue='${data[stc[0]]}'`).map(col => col);
          } else {
              //actions = alasql(`select * from workflows where Module ='${resource}' and   role_${role}='control' and  StateColumn='${stc[0]}' and StateValue=${record[stc[0]]}`).map(col => col);
              actions = alasql(`select * from workflows where Module ='${resource}' and   role_${role}='control' and  StateColumn='${stc[0]}' and StateValue='${data[stc[0]]}'`).map(col => col);

          }
          //get actions objects

          if (typeof actions[0] !== 'undefined' && typeof actions[0].Actions !== 'undefined') {
              let actData = actions[0].Actions;
              if (actData != '[]') {

                  Hooks = alasql(`select * from workflows where Module ='${resource}' and   role_${role}='control' and  WorkFlowName= ANY (ARRAY${actData})`).map(col => col);
                  workflow = actions[0].WorkFlowName;
              }
          }
      }
  }
  return (
    <TopToolbar>
{/*       <EditButton basePath={basePath} record={data} />
 */}
      {Hooks.map((f, index) => <WorkFlowButton actData={f} key={index} basePath={basePath} record={data} />)}

    </TopToolbar>
  )
};





//export default 
class ResourceShow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      resourcesFields: [],
      tabs:[]
    };
  }

  async componentDidMount() {
    const { resource,record } = this.props;
    console.log(this.props);
    const role = localStorage.getItem('role');

    // let resourcesFields = await alasql.promise(
    //   `select source_name, field_widget_type, field_meta from resources 
    //   where 
    //   resource_name ='${resource}'  and is_show_field =true 
    //   AND ( role_${role}='read' OR role_${role}='write' )  `
    // );

    let tabs  = alasql( `select distinct write_group as tab  from resources where resource_name ='${resource}' and ( role_${role}='read' OR role_${role}='write') `  ).map(({tab})=>tab);
    let resourcesFields  = alasql(`select distinct write_group as tab  from resources where resource_name ='${resource}'`).map(({tab})=> alasql(`select  source_name, field_widget_type, field_meta , css_class  from resources where write_group ="${tab}" and resource_name ='${resource}'  and is_show_field =true
    AND  ( role_${role}='read' OR role_${role}='write' ) ` ) )

    let headerFields = alasql(`select  source_name, input_widget_type, field_meta , annotation  from resources where  resource_name ='${resource}'  and is_show_field =true
    AND ( role_${role}='write' ) and annotation ="header"`  );


    this.setState({
      loading: false,
      resourcesFields,
      headerFields,
      tabs
    });
  }

  renderField(f, index) {
    const { record,id } = this.props;
    return generateField(f, index, record,'',id);
  }


  render() {
    const { loading, resourcesFields , tabs=[], headerFields=[]} = this.state;
    if (loading) return "loading ...";

    return (
      <>
      {headerFields.length > 0 && 
      <Show {...this.props} actions={<></>}>
        <SimpleShowLayout>
          {headerFields.map((f, index) => <TextField source={f.source_name} className="show-field-compressed"  key={index} />)}

        </SimpleShowLayout>
      </Show>
      }
      <hr/>
      
      <Show  actions={<ShowActions />}  {...this.props}>
        <TabbedShowLayout {...this.props}>
            {tabs.map((t, index)=>(
              <Tab label={t} key={index} {...this.props}>
                {resourcesFields[index].map((f, index) => this.renderField(f, index))}
              </Tab>
            )) }
          </TabbedShowLayout>
      </Show>
      </>
    );
  }
}


export default  reduxForm({
  form: 'record-form',
  enableReinitialize: true,

})(ResourceShow)