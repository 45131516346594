// Authenticatd by default
import decodeJwt from "jwt-decode";

export default {
  login: ({ username, password }) => {

    

    const request = new Request('https://api.matloob.sa/api/admin_login', {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" })
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(response => {
    

        if (response.user!=null) {
   
          localStorage.setItem('role', 'admin');
          localStorage.setItem('authenticated', true);
          localStorage.setItem('tokenLogin', 'token');
          localStorage.setItem('user_name', username);
          localStorage.setItem('user_id', '1');
          window.location.reload();

          return Promise.resolve();


        }
        
        

        localStorage.removeItem('authenticated');
        localStorage.removeItem('tokenLogin');
        localStorage.removeItem('acls');
        localStorage.removeItem('user_name');
        localStorage.removeItem('user_id');
        

        return Promise.reject();






        // localStorage.setItem("token", token);
      });



  },
  logout: () => {
    localStorage.removeItem('authenticated');
    localStorage.removeItem('role');
    localStorage.removeItem('authenticated');
    localStorage.removeItem('tokenLogin');
    localStorage.removeItem('acls');
    localStorage.removeItem('user_name');
    localStorage.removeItem('user_id');
    return Promise.resolve();
  },
  checkError: ({ status }) => {
    return status === 401 || status === 403
      ? Promise.reject()
      : Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem('authenticated')
      ? Promise.resolve()
      : Promise.reject();
  },
  getPermissions: () => {
    const role = localStorage.getItem('role');
    return Promise.resolve(role);
  },
};
