import { FixedSizeList } from 'react-window';
import { components } from 'react-select';
import React from 'react';
const ValueCont = ({ children, getValue, ...props }) => {
    var length = getValue().length;
  
    return (
      <components.ValueContainer {...props}>
        {!props.selectProps.menuIsOpen &&
          `${length} Item${length != 1 ? "s" : ""} selected`}
        {React.cloneElement(children[1])}
      </components.ValueContainer>
    );
  };
export const optimizeSelect = {
    components: {
        MenuList: OptimizedMenuList,
       // Option: OptimizedOption,
        ValueContainer:ValueCont
    },
};
function OptimizedMenuList(props) {
    const { options, children, maxHeight, getValue } = props;
    if (!children || !Array.isArray(children))
        return null;
    const height = 50;
    const selectedValues = getValue();
    const initialOffset = selectedValues[0] ? options.indexOf(selectedValues[0]) * height : 0;
    return(
        <FixedSizeList
            height={maxHeight}
            itemCount={children.length}
            itemSize={height}
            initialScrollOffset={initialOffset}
            width={300} // 100% width
            style={{ direction: "rtl" }}
        >
            {({ index, style }) => <div className="option-wrapper" style={style}>{children[index]}</div>}
        </FixedSizeList>
    );
    //return (React.createElement(FixedSizeList, { width: '', itemSize: height, height: maxHeight, itemCount: children.length, initialScrollOffset: initialOffset }, ({ index, style }) => (React.createElement("div", { className: "option-wrapper", style: style }, children[index]))));
}
function OptimizedOption(props) {
    delete props.innerProps.onMouseMove;
    delete props.innerProps.onMouseOver;
    return React.createElement(components.Option, Object.assign({}, props), props.children);
}
 
