import React from "react";
import ReactMapboxGl, { GeoJSONLayer } from "react-mapbox-gl";
import { Field, reduxForm } from "redux-form";
import get from "lodash/get";

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoiZmFrZXVzZXJnaXRodWIiLCJhIjoiY2pwOGlneGI4MDNnaDN1c2J0eW5zb2ZiNyJ9.mALv0tCpbYUPtzT7YysA2g"
});

const MapField = ({ className, source, name, record = {}, ...rest }) => {
  const geojson = get(record, source);
  const lineLayout = {
    "line-cap": "round",
    "line-join": "round"
  };

  const linePaint = {
    "line-color": "#4790E5",
    "line-width": 2
  };

  const fillPaint = {
    "fill-color": "yellow",
    "fill-opacity": 0.4
  };



  const circleLayout = { visibility: "visible" };
  const circlePaint = {
    "circle-color": "green"
  };


  return (
    <div>
      <Map
        style="mapbox://styles/mapbox/streets-v9" // eslint-disable-line
        containerStyle={{
          height: "600px",
          width: "100%",
          direction: "ltr"
        }}
      >
        <GeoJSONLayer
          data={geojson}
          circleLayout={circleLayout}
          circlePaint={circlePaint}
          fillPaint={fillPaint}
          lineLayout={lineLayout}
          linePaint={linePaint}
        />
      </Map>
    </div>
  );
};

export default MapField;