import React, { Component , Fragment, cloneElement, useMemo } from "react";
import {
  List,CardActions,CreateButton,TopToolbar, ExportButton, Button, sanitizeListRestProps,useUnselectAll
} from "react-admin";
import { reduxForm } from 'redux-form'
// import jsonExport from 'jsonexport/dist';
import alasql from "alasql";
import PostBulkActionButtons from './ui/PostBulkActionButtons'

import DefaultListGrid from './ui/DefaultListGrid'
import TabedListGrid from './ui/TabedListGrid'
import ListFilters from './ui/ListFilters'
//import  ListActions  from './ui/ListActions'
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { push } from 'react-router-redux';
import { withStyles } from '@material-ui/core';
import { Route, Switch } from 'react-router';
import { Drawer } from '@material-ui/core';
import ResourceEdit from "./Edit";
import ResourceCreate from "./Create";
import IconEvent from '@material-ui/icons/Event';
import ActionButton from "./ActionButton";

const filterStyles = {
  status_id: { width: 150 }
};


const styles = {
  drawerContent: {
      width: 500
  }
};



const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
      })}
      <CreateButton basePath={basePath} />
      <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={{ ...filterValues, ...permanentFilter }}
          exporter={exporter}
          maxResults={maxResults}
      />
      {/* Add your custom actions 
      <Button
          onClick={() => { alert('Your custom action'); }}
          label="Show calendar"
      >
          <IconEvent />
      </Button>
      */}
  </TopToolbar>
);

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};



// const exporter = posts => {
//   const postsForExport = posts.map(post => {
//       const { backlinks, author, ...postForExport } = post; // omit backlinks and author
//       //postForExport.author_name = post.author.name; // add a field
//       return postForExport;
//   });
//   jsonExport(postsForExport, {
//       //headers: ['id', 'title', 'author_name', 'body'] // order fields in the export
//   }, (err, csv) => {
//       downloadCSV(csv, 'data'); // download as 'posts.csv` file
//   });
// }



class ResourceList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      resourcesFields: [],
      resourcePermistions: [],
      tabs: {},
      stateColumn:null,
      stateValue:0
      
    };
   

 
  }
  onTabChange = (Column, Value) => {

    const { stateColumn, stateValue } = this.state;

 
    if (stateColumn != Column || Value != stateValue) {

    //  unselectAll();
      this.setState({
        stateColumn: Column,
        stateValue: Value
      })

    }

  }
 
  async componentDidMount() {
    const { resource } = this.props;
    const role = localStorage.getItem('role');

    let resourcePermistions = await alasql.promise(
      `select extra,resource_name,is_expand,expand_resource, expand_source, expand_destination,list_is_tab,list_tabs,list_tab_filter,list_tab_filter_value,resource_name,inline_update,inline_create, admin_show, admin_edit, admin_delete,supervisor_show, supervisor_edit, supervisor_delete  from resource_permistions  where resource_name ='${resource}' `
    );

    let resourcesFields = await alasql.promise(
      `
      select source_name,field_widget_type, field_meta, css_class from resources 
      WHERE 
      resource_name ='${resource}' AND is_list_field =true 
      AND ( role_${role}='read' OR role_${role}='write' ) 
      `
    );

    this.setState({
      loading: false,
      resourcesFields,
      resourcePermistions,
    });
    
  }

  


  render() {
    const { loading, resourcesFields, resourcePermistions ,stateColumn,stateValue} = this.state;
    const {  push, classes, ...props} = this.props;

    let editRoute=`/${this.props.resource}/:id`;
    let createRoute=`/${this.props.resource}/create`;



    var expandEdit=false;
    var expandEditColumn="";
    var expandEditValue="";
    if (resourcePermistions[0] && resourcePermistions[0].extra && resourcePermistions[0].extra!=''){
        let extra = JSON.parse(resourcePermistions[0].extra);
        
        if(extra && extra.Edit=="true"){
            expandEdit=true;
             expandEditColumn=extra.EditColumn;
             expandEditValue=extra.EditValue;
        }

    }
    




    if (loading) return "loading ...";
    if (resourcePermistions[0].list_is_tab) {
      resourcePermistions[0].list_tab_filter_value=parseInt(resourcePermistions[0].list_tab_filter_value);
      return (
        <Fragment >
        <List {...props}  bulkActionButtons={<PostBulkActionButtons {...props} resource={this.props.resource}  Column={stateColumn} Value={stateValue}  />}  actions={<ListActions />} filters={<ListFilters />} filterDefaultValues={{ [resourcePermistions[0].list_tab_filter]: resourcePermistions[0].list_tab_filter_value }}  >

        <TabedListGrid {...props}  onTabChange={this.onTabChange} resourcePermistions={resourcePermistions[0]} resourcesFields={resourcesFields} />
        </List>
        { resourcePermistions[0].inline_create && (

        <Route path={createRoute} >
                    {({ match }) => (
                        <Drawer
                            open={!!match}
                            anchor="right"
                            onClose={this.handleClose}
                        >
                            <ResourceCreate
                                className={styles.drawerContent}
                                onCancel={this.handleClose}
                                {...props}
                            />
                        </Drawer>
                    )}
                </Route>
                 )}

                { resourcePermistions[0].inline_update && (
                <Route path={editRoute} >
                    {({ match  }) => {
                        const isMatch =
                            match &&
                            match.params &&
                            match.params.id !== 'create' ;

                        return (
                            <Drawer
                                open={isMatch}
                                anchor="right"
                                onClose={this.handleClose}
                            >
                                {isMatch ? (
                                    <ResourceEdit
                                        className={classes.drawerContent}
                                        id={isMatch ? match.params.id : null}
                                        onCancel={this.handleClose}
                                        {...props}
                                    />
                                ) : (
                                    <div className={classes.drawerContent} />
                                )}
                            </Drawer>
                        );
                    }}
                </Route>
                )}
       </Fragment>

      );
    } 
    
    else {
      return (
        <Fragment >

        <List {...props} bulkActionButtons={false} filters={<ListFilters />}   actions={<ListActions />}

       bulkActionButtons={expandEdit && <PostBulkActionButtons   {...props} resource={this.props.resource}  Column={expandEditColumn} Value={expandEditValue}   />}

        // aside={<iframe src="https://airtable.com/embed/shrPEPx87s3Ygscbg?backgroundColor=pink" width="300" height="100%" frameBorder="0">Browser not compatible.</iframe>}
        //rowClick="show"
        >

          <DefaultListGrid {...props} resourcePermistions={resourcePermistions[0]}  resourcesFields={resourcesFields} />
        </List>


        <Route path={createRoute} >
                    {({ match }) => (
                        <Drawer
                            open={!!match}
                            anchor="right"
                            onClose={this.handleClose}
                        >
                            <ResourceCreate
                                className={styles.drawerContent}
                                onCancel={this.handleClose}
                                {...props}
                            />
                        </Drawer>
                    )}
                </Route>
                { resourcePermistions[0].inline_update && (
                <Route path={editRoute} >
                    {({ match  }) => {
                        const isMatch =
                            match &&
                            match.params &&
                            match.params.id !== 'create' ;

                        return (
                            <Drawer
                                open={isMatch}
                                anchor="right"
                                onClose={this.handleClose}
                            >
                                {isMatch ? (
                                    <ResourceEdit
                                        className={classes.drawerContent}
                                        id={isMatch ? match.params.id : null}
                                        onCancel={this.handleClose}
                                        {...props}
                                    />
                                ) : (
                                    <div className={classes.drawerContent} />
                                )}
                            </Drawer>
                        );
                    }}
                </Route>
                )}
        </Fragment>


      );
    }

  }
  handleClose = () => {
    const { resource } = this.props;

    this.props.push(`/${resource}`);
};
}

/*

export default reduxForm({
  form: 'list',
  enableReinitialize: true,

})(ResourceList)
*/

export default compose(
  connect(
      undefined,
      { push }
  ),
  withStyles(styles)
)(ResourceList);
