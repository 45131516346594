import React, { Component } from "react";
import ReactMapboxGl from "react-mapbox-gl";
import DrawControl from "react-mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import get from "lodash/get";

const MAPBOX_ACCESS_TOKEN = localStorage.getItem('MAPBOX_ACCESS_TOKEN');

const Map = ReactMapboxGl({
  accessToken:  MAPBOX_ACCESS_TOKEN
});

export default class MapInput extends Component {
  constructor(props) {
    super(props);
    this.drawControl = null;
    this.state = {
      center:[46.707232, 24.704463, ],
      zoom:[10]
    };
  }

  loadData(){
    const { source, record = {} }= this.props;
    const geojson = get(record, source);
    if(geojson) this.drawControl.draw.add(geojson);
  }

  onDrawCreate({ features }) {
    const {
      input: { value, onChange }
    } = this.props;
    onChange(this.drawControl.draw.getAll());
  }

  onDrawUpdate({ features }) {
    const {
      input: { value, onChange }
    } = this.props;
    onChange(this.drawControl.draw.getAll());
  }

  render() {

    const { center , zoom } = this.state;
    const mapStyle = "mapbox://styles/mapbox/satellite-streets-v9"//"mapbox://styles/mapbox/light-v9"

    return (
      <div style={{clear:'both', margin: 30}}>
        <Map
          onStyleLoad={()=> this.loadData()}
          style={mapStyle}
          
          center={center}
           zoom={zoom}
          containerStyle={{
            height: "600px",
            width: "100%",
            direction: "ltr"
          }}
        >
          <DrawControl
            ref={drawControl => {
              this.drawControl = drawControl;
            }}
            onDrawCreate={(props)=> this.onDrawCreate(props)}
            onDrawUpdate={(props)=> this.onDrawUpdate(props)}
          />
        </Map>
      </div>
    );
  }
}
