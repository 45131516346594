// in src/App.js
import React, { Component } from "react";
import alasql from "alasql";
import jwtDecode from "jwt-decode";
import { find } from "lodash";
import queryString from "query-string";
import { RTL } from "./core/layout/rtl";
import MyLayout from "./MyLayout";
import { Admin, Resource,Login, } from "react-admin";
import PostIcon from "@material-ui/icons/Book";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Route } from "react-router-dom";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import ResourceList from "./core/resource/List";
import ResourceEdit from "./core/resource/Edit";
import ResourceCreate from "./core/resource/Create";
import ResourceShow from "./core/resource/Show";
import addUploadFile from "./core/addUploadFile";
//import { API_ENDPOINT } from './core/config'
import "./core/assets/css/custom.css";
import { getMessages } from "./core/utils/lang";
import { Helmet } from "react-helmet";
// import MapboxGl from "mapbox-gl";
import { fetchUtils } from "react-admin";

import { loadFromairtable } from './core/api/airtable'
import { dataProvider } from "./dataProvider";
import authProvider from "./authProvider";
import { makeStyles } from '@material-ui/core/styles';

import { Dashboard } from './dashboard';
const useStyles = makeStyles(theme => ({
  main: {
    background: 'radial-gradient(circle at 50% 14em, #000 0%, #000 60%, #000 100%)', 
  },
}));

const MyLoginPage = props => {
  const classes = useStyles()
  return <Login classes={classes}  {...props} />
}


// MapboxGl.setRTLTextPlugin(
//   "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js"
// );

let messages = {};
window.alasql = alasql;
export default class App extends Component {
  constructor(props) {
    super(props);


    let API_ENDPOINT = null;
    // let airTableToken='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYWRtaW4iLCJhcGlLZXkiOiJrZXlTVGV4bVNvbG5BMUFZWSIsImJhc2VOYW1lIjoiYXBwcDNINklhUDJ3amJiSTIifQ.lhQ97bAwktOXRhemaZr-VyvN6HFw25WRnURgVD-pyd0'
    //var parsed = queryString.parse(window.location.search);

    //let token = jwtDecode(airTableToken);
    //console.log('token',token);
    const { api, role, apiKey, baseName, secret } = {
      "role":localStorage.getItem("role")?localStorage.getItem("role"):'none',
      "apiKey": "keybwsi4UVudGdVX5",
      //"baseName": "appjEjThc7tJY967w"
      "baseName": "appjFQHrRw2kJHPN9"

    };


    localStorage.setItem("API_ENDPOINT", api);
    localStorage.setItem("secret", secret);
    localStorage.setItem("apiKey", apiKey);
    localStorage.setItem("baseName", baseName);


    this.DIRECTION = "ltr";
    this.state = {
      loading: true,
      resources: null,
      resource_names: null,
      role: role,
      resourcePermistions: null,
      customRoutes: []
    };
  }

  async componentDidMount() {



    let API_ENDPOINT = localStorage.getItem("api");
    let apiKey = localStorage.getItem("apiKey");
    let secret = localStorage.getItem("secret");
    let baseName = localStorage.getItem("baseName");
    let headers = {
      "content-type": "application/json",
      "x-hasura-admin-secret": secret
      //authorization: "bearer <token>"
    };


    let r = await fetch(`${API_ENDPOINT}/token`);
    // let uToken = await r.text();
    // let usersData = jwtDecode(uToken);

    // alasql("CREATE TABLE IF NOT EXISTS users");
    // alasql("SELECT * INTO users from ? ", [usersData]);

    // load from airtable
    let adminTable = await loadFromairtable({ apiKey, baseName, tableName: 'admin', fields: ['name', 'value', 'notes'] });
    let translationsTable = await loadFromairtable({ apiKey, baseName, tableName: 'translations', fields: ['level1', 'level2', 'level3', 'level4', 'ar', 'en', 'level5'] });
    let resourcesTable = await loadFromairtable({ apiKey, baseName, tableName: 'resources', fields: ['edit_input_widget_type','is_expand_field','is_inline', 'resource_name', 'read_group', 'source_name', 'source_name_alias', 'annotation', 'is_filter', 'field_meta', 'field_type', 'field_widget_type', 'input_widget_type', 'filter_widget_type', 'is_enabled', 'is_list_field', 'is_show_field', 'is_edit_field', 'is_create_field', 'role_admin',  'role_supervisor', 'write_group', 'full_width','is_required','css_class'] });
    let resourcePermistionsTable = await loadFromairtable({ apiKey, baseName, tableName: 'resource_permistions', fields: ['extra','is_expand','expand_resource','expand_source','expand_destination','menu_section', 'list_is_tab', 'list_tabs', 'list_tab_filter','list_tab_filter_value', 'form_mode', 'inline_update', 'inline_create', 'resource_name', 'admin_list', 'admin_show', 'admin_add', 'admin_edit', 'admin_delete',  'supervisor_list', 'supervisor_show', 'supervisor_add', 'supervisor_edit', 'supervisor_delete'] });
    let tabsTable = await loadFromairtable({ apiKey, baseName, tableName: 'custom_tabs', fields: ['resource_name', 'tab_id', 'width_class', 'new_line_after'] });
    let workFlow = await loadFromairtable({ apiKey, baseName, tableName: 'workflow', fields: ['WorkFlowName','Module','StateColumn','StateValue','resources','role_admin','role_supervisor','role_inspector','title','icon','alert_accept','alert_error','Actions'] });



    //let customRoutesTable = await loadFromairtable({tableName:'custom_routes'});

    // console.log('airtableData',airtableData)

    await alasql.promise([
      // "CREATE TABLE IF NOT EXISTS users",
      "CREATE TABLE IF NOT EXISTS admin",
      "CREATE TABLE IF NOT EXISTS translations",
      "CREATE TABLE IF NOT EXISTS resources",
      "CREATE TABLE IF NOT EXISTS resource_permistions",
      "CREATE TABLE IF NOT EXISTS custom_routes",
      "CREATE TABLE IF NOT EXISTS custom_tabs",
      "CREATE TABLE IF NOT EXISTS workflows",

      //`SELECT * INTO users FROM CSV("${API_ENDPOINT}/users.csv",{headers:true})`,
      //`SELECT * INTO admin FROM CSV("${API_ENDPOINT}/admin.csv",{headers:true})`,
      //`SELECT * INTO translations FROM CSV("${API_ENDPOINT}/translations.csv",{headers:true})`,
      //`SELECT * INTO resources FROM CSV("${API_ENDPOINT}/resources.csv",{headers:true})`,
      // `SELECT * INTO resource_permistions FROM CSV("${API_ENDPOINT}/resource_permistions.csv",{headers:true})`,
      //`SELECT * INTO custom_routes FROM CSV("${API_ENDPOINT}/custom_routes.csv",{headers:true})`
    ]);

    alasql('SELECT * INTO admin FROM ?', [adminTable]);
    alasql('SELECT * INTO translations FROM ?', [translationsTable]);
    alasql('SELECT * INTO resources FROM ?', [resourcesTable]);
    alasql('SELECT * INTO resource_permistions FROM ?', [resourcePermistionsTable]);
    alasql('SELECT * INTO custom_tabs FROM ?', [tabsTable]);
    alasql('SELECT * INTO workflows FROM ?', [workFlow]);
    //alasql('SELECT * INTO custom_routes FROM ?',[customRoutesTable]);
    // alasql('SELECT * INTO resource_permistions FROM ?',[airtableData]);


    let admin_data = alasql("select * from admin");

    const { value: GRAPHQL_ENDPOINT = "" } = find(admin_data, {
      name: "GRAPHQL_ENDPOINT"
    });
    const { value: UPLOAD_SERVER_ENDPOINT = "" } = find(admin_data, {
      name: "UPLOAD_SERVER_ENDPOINT"
    });
    const { value: DOWNLOAD_SERVER_ENDPOINT = "" } = find(admin_data, {
      name: "DOWNLOAD_SERVER_ENDPOINT"
    });
    const { value: MAPBOX_ACCESS_TOKEN = "" } = find(admin_data, {
      name: "MAPBOX_ACCESS_TOKEN"
    });
    const { value: COLOR = "purple" } = find(admin_data, { name: "COLOR" });

    this.TITLE = find(admin_data, { name: "TITLE" }).value;

    localStorage.setItem("UPLOAD_SERVER_ENDPOINT", UPLOAD_SERVER_ENDPOINT);

    localStorage.setItem("GRAPHQL_ENDPOINT", GRAPHQL_ENDPOINT);
    localStorage.setItem("DOWNLOAD_SERVER_ENDPOINT", DOWNLOAD_SERVER_ENDPOINT);
    localStorage.setItem("MAPBOX_ACCESS_TOKEN", MAPBOX_ACCESS_TOKEN);
    localStorage.setItem("COLOR", COLOR);
    localStorage.setItem("TITLE", this.TITLE);

    let LOCAL = find(admin_data, { name: "LOCAL" }).value;
    this.DIRECTION = find(admin_data, { name: "DIRECTION" }).value;





    const httpClient = (url, options = {}) => {
      if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
      }
      // add your own headers here
      //options.headers.set('authorization', "bearer "+localStorage.getItem("token"));
      options.headers.set('authorization', "bearer " + 'STATIC');
      return fetchUtils.fetchJson(url, options);
    }




    this.uploadCapableFileProvider = addUploadFile(dataProvider);

    messages = {
      ar: getMessages(LOCAL)
    };
    const role = localStorage.getItem("role");

    let resources = alasql("select * from resources");
    let resource_names = alasql(
      `select distinct resource_name from resources where resource_name <> '' AND ( role_${role}='read' OR role_${role}='write' ) `
    );

    let custom_routes = alasql(`select * from custom_routes where name <> '' `);

    let resourcePermistions = alasql(
      'select * from resource_permistions  where resource_name <> "" '
    );

    this.setState({
      loading: false,
      resources,
      resource_names,
      GRAPHQL_ENDPOINT,
      role,
      resourcePermistions,
      customRoutes: custom_routes
    });
  }

  render() {
    // Localization
    const { role, customRoutes } = this.state;
    //const i18nProvider = locale => this.messages[locale];
    const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'ar');

    const {
      loading,
      resource_names,
      GRAPHQL_ENDPOINT,
      resourcePermistions
    } = this.state;
    

    if (loading)
      return (
        <div style={{ textAlign: "center", padding: 150 }}>
          <LinearProgress />
        </div>
      );
    return (
      <>
        <Helmet>
          <title>{this.TITLE}</title>
        </Helmet>
        <div style={{ direction: this.DIRECTION }}>
          <RTL>
            <Admin
              layout={MyLayout}
              loginPage={MyLoginPage}
              //locale="ar"
              dashboard={Dashboard}
              i18nProvider={i18nProvider}
              dataProvider={this.uploadCapableFileProvider}
              authProvider={authProvider}
              customRoutes={customRoutes.map(({ link, url }) => (
                <Route
                  exact
                  path={link}
                  component={() => (
                    <iframe
                      width="100%"
                      height="700px"
                      frameBorder="0"
                      src={url}
                    />
                  )}
                />
              ))}
            >
              {resourcePermistions &&
                resourcePermistions.map((resourcePermition, index) => {
                  const permition = alasql(
                    `SELECT distinct role_${role} as the_role FROM resources WHERE resource_name ='${resourcePermition.resource_name}' `
                  ).map(({ the_role }) => the_role);
                  const canEdit =
                    resourcePermition[`${role}_edit`] == true && resourcePermition[`inline_update`] == false;
                  const canDelete =
                    resourcePermition[`${role}_delete`] == true;
                  const canList =
                    resourcePermition[`${role}_list`] == true;
                  const canShow =
                    resourcePermition[`${role}_show`] == true;
                  const canAdd = resourcePermition[`${role}_add`] == true && resourcePermition[`inline_create`] == false;

                  /*     console.log(
                        resourcePermition,
                        canEdit,
                        canDelete,
                        canList,
                        canShow,
                        canAdd
                      ); */

                  return (
                    <Resource
                      name={resourcePermition.resource_name}
                      icon={PostIcon}
                      list={canList && ResourceList}
                      edit={canEdit && ResourceEdit}
                      create={canAdd && ResourceCreate}
                      show={canShow && ResourceShow}
                      key={index}
                    />
                  );
                })}
            </Admin>
          </RTL>
        </div>
      </>
    );
  }
}
