
import React, { Component } from "react";
import {

  Filter,

} from "react-admin";
import alasql from "alasql";
import { asyncForEach } from '../../utils/utils'
import { generateInputField, generateField, generateFilterField } from "../factory";
class ListFilters extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        filterData: []
      };
    }
  
    async componentDidMount() {
      const { resource } = this.props;
      const role = localStorage.getItem('role');
  
  
      let filterData = alasql(`select  source_name, filter_widget_type , input_widget_type, field_meta , annotation,workflows_read,'none' as workflow  from resources where  resource_name ='${resource}'  and is_filter =true
      AND ( role_${role}='write' ) `)
  
  
  
  
      this.setState({
        loading: false,
        filterData
      });
    }
  
    renderField(f, index) {
      return generateInputField(f, index,this.props.id);
    }
  
    renderFilterField(f, index) {
      f.input_widget_type = f.filter_widget_type;
      return generateFilterField(f, index,this.props.id);
    }
    render() {
      const { filterData, choicesObject } = this.state;
      return (
        <Filter {...this.props}>
          {filterData.map((f, index) => this.renderFilterField(f, index))}
  
        </Filter>
      );
    }
  }


export default ListFilters;