import React from "react";
import {
  TextInput,
  TextField,
 
  
} from "react-admin";

import {factoryMap} from './componentMap'


// function factoryElements(f, index, input_widget, component, className, record) {
//   const { field_widget_type, field_meta, source_name, css_class, edit_css_class, full_width } = f;
//   let choices = {};
//   let meta, reference, referancesRourceName, referancesSource;
//   //console.log('input_widget', input_widget, field_meta, source_name, css_class, edit_css_class, full_width);
//   return factoryMap(f, index, input_widget, component, className, record);
// }



export const generateInputField = (f, index,id) => {
  const {  input_widget_type, source_name } = f;
  let component = <TextInput source={source_name} key={index} />;

  return factoryMap(f, index, input_widget_type, component, '', '',id);

};

export const generateField = (f, index, record, className = "",id) => {

  const { field_widget_type, source_name, css_class} = f;

  let component = (
    <TextField
      source={source_name}
      key={index}
      className={`show-field ${css_class} ${className}`}
    />
  );
  return factoryMap(f, index, field_widget_type, component, className, record,id);

};

export const generateHeaderField = (f, index, record,id) => {
  const { field_widget_type,  source_name } = f;

  let component = (
    <TextField source={source_name} key={index} className='show-field-compressed' />
  );
  component = factoryMap(f, index, field_widget_type, component, 'show-field-compressed', record,id);

  return component;
};
export const generateFilterField = (f, index, record,id) => {
  let className='';
  const { filter_widget_type, field_meta, source_name, css_class, edit_css_class } = f;

  let component = (
    <TextField
      source={source_name}
      key={index}
      className={`show-field ${css_class} ${className}`}
    />
  );
  return factoryMap(f, index, filter_widget_type, component, className, record,id);


};
