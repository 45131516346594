import React, { forwardRef } from 'react';
import { AppBar, UserMenu, MenuItemLink, useTranslate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import myData from './appversion.json';


const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});



const CustomAppBar = props => {
    const classes = useStyles();
    console.log(myData);
    return (
        <AppBar {...props} >
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <span>{localStorage.getItem("TITLE")}                  ....  اصدار تجريبي({myData.version.major}.{myData.version.minor})</span>
            <span className={classes.spacer} />
        </AppBar>
    );
};

export default CustomAppBar;