
import React from "react";
import {
  FormWithRedirect,
  SaveButton,   
  Toolbar,
  translate
} from "react-admin";
import { generateInputField } from "../factory";
import {
  useMediaQuery, makeStyles, Typography, Box, Grid

} from '@material-ui/core';
import Button from '@material-ui/core/Button';

const useDatagridStyles = makeStyles({
  total: { fontWeight: 'bold' },
});

const UiInlineForm = (props) => (

  <FormWithRedirect
  {...props}
    render={({ resourcesFields, resourcesTabs, tabs, canDelete, formProps,onCancel,id, ...props }) => (
      // here starts the custom form layout
      <form>
        <div dir={'rtl'} style={{ padding: 1 }}>
          {tabs.map((t, index) => {

            return (
              <>
                {resourcesFields[index] && resourcesFields[index].map((f, index) =>
                  generateInputField(f, index,id)
                )}


              </>

            );


          }

          )}

        </div>
        <Toolbar {...props}>
          <SaveButton {...props} />
          <Button {...props} onClick={onCancel}>{translate('ra.action.cancel')}</Button>
        </Toolbar>
      </form>
    )}
  />
);



export default UiInlineForm;