
import React, { Fragment } from "react";
import { SaveButton, DeleteButton, EditButton, ShowButton, Datagrid,useUnselectAll } from "react-admin";

import { generateField } from "../factory";
import ExpandGrid from "./ExpandGrid";

import {
  makeStyles,
  useMediaQuery,
  Divider,
  Tabs,
  Tab,
} from '@material-ui/core';

const useDatagridStyles = makeStyles({
  total: { fontWeight: 'bold' },
});

class TabedGrid extends React.Component {

  constructor(props) {
    super(props);

    this.state = eval(props.resourcePermistions.list_tabs).map(function (a) {
      let obj = {};
      let key = a.id;
      return obj[key] = [];
    })
  }


  static getDerivedStateFromProps(props, state) {
    if (props.ids !== state[props.filterValues[props.resourcePermistions.list_tab_filter]]) {
      return { ...state, [props.filterValues[props.resourcePermistions.list_tab_filter]]: props.ids };
    }
    return null;
  }

  handleChange = (event, value) => {
    const { filterValues, setFilters, resourcePermistions,unselectAll,resource } = this.props;
    unselectAll(resource);
    

    setFilters({ ...filterValues, [resourcePermistions.list_tab_filter]: value });
  };

   componentDidMount() {
     const {
       isXSmall,
       classes,
       filterValues,
       resourcesFields,
       resourcePermistions,
       onTabChange,
       ...props
     } = this.props;
     console.log('xxx');

  

   }



  render() {
    const { isXSmall, classes, filterValues, resourcesFields, resourcePermistions,onTabChange, ...props } = this.props;
    const role = localStorage.getItem('role');

    eval(resourcePermistions.list_tabs).map(choice => {
      if (filterValues[resourcePermistions.list_tab_filter] === choice.id) {

        if (typeof onTabChange != 'undefined') {
          onTabChange(choice.key, choice.value);
          console.log(choice.key);
        }

        
      }
    });

    return (
      <Fragment>
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues[resourcePermistions.list_tab_filter]}
          indicatorColor="primary"
          onChange={this.handleChange}
        >
          {eval(resourcePermistions.list_tabs).map(choice => (
            <Tab
              key={choice.id}
              label={choice.name}
              value={choice.id}
            />
          ))}
        </Tabs>
        <Divider />

        <div>
          {eval(resourcePermistions.list_tabs).map(choice => {
            if (filterValues[resourcePermistions.list_tab_filter] === choice.id) {
             
               //onTabChange(choice.key,choice.value);
               console.log(resourcePermistions);
             

              return (
                <Datagrid
                  {...props}
                  ids={this.state[choice.id]}
                  
                  // rowClick="edit"
                  expand={resourcePermistions.is_expand ? <ExpandGrid  linkedResourcePermistions={resourcePermistions}/> : null}
                >
                  {resourcesFields.map((f, index) => generateField(f, index, null, 'list-view', props.id))}

             

                  {resourcePermistions && resourcePermistions[`${role}_edit`]== true && choice.allowEdit==true && <EditButton />}
                  {resourcePermistions && resourcePermistions[`${role}_delete`]== true && choice.allowDelete==true && <DeleteButton />}
                  {resourcePermistions && resourcePermistions[`${role}_show`]== true && <ShowButton />}

                </Datagrid>
              );
            }


          })}


        </div>
      </Fragment>
    );
  }
}

const TabedListGrid = (props) => {
  const classes = useDatagridStyles();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const unselectAll = useUnselectAll();


  return <TabedGrid {...props} unselectAll={unselectAll}  />;
};

export default TabedListGrid;